@import "../../../../assets/styles/variables.module.scss";

.department {
  position: static;
  margin-top: 1rem;
  background: $white;
  padding: 0.875rem;
  border-radius: 7px;

  @media (max-width: 768px) {
    height: 100%;
    margin-right: 2rem;
  }

  cursor: pointer;
  border: 1px solid $white;
  z-index: 1;

  &.active {
    background: $ceruleanBlue;
    color: $white;
    border: 1px solid $ceruleanBlue;

    .department__name {
      color: $white;
    }
  }

  &.hover {
    background: $aliceBlue;
    border: 1px solid $borderBlue;
    color: $riverBed;

    .department__name {
      color: $riverBed;
    }
  }

  &:nth-child(1) {
    margin-top: 0;
    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  }

  &__name {
    font-size: 16px;
    color: $riverBed;
  }

  .child-departments {
    position: absolute;
    top: 0;
    left: 195px;
    z-index: 888;

    &__list {
      margin-left: 2rem;
      background-color: $white;
      border: 1px solid $lineColor;
      list-style: none;
      border-radius: 12px;
      display: none;
      z-index: 888;
      padding: 0;
      overflow: hidden;

      &.active {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
