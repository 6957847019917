@import "../../../assets/styles/variables.module.scss";

.new-password {
  color: $steelGray;
  margin-top: 5%;
  width: 50%;

  @media (max-width: 768px) {
    width: 85%;
  }

  &__paragraphs {
    list-style: none;
    display: grid;
    padding-inline-start: 0;

    grid-template-columns: repeat(2, 0.35fr);
    row-gap: 10%;
    &--error-paragraph,
    &--valid-paragrah {
      display: flex;
      gap: 3%;
      align-items: center;
    }
    &--valid-paragrah {
      color: $limeade;
    }
    &--error-paragraph {
      color: $baliHai;
    }
  }

  &__warning-pargraph {
    color: $flamenco;
    font-size: 10px;
    margin-top: -2%;
  }

  &__header {
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  &__description {
    margin-bottom: 0.5rem;
    text-align: left;
    &__error-header {
      color: $torchRed;
    }

    &__text {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
  }

  &__input {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: $heather;
    line-height: 1.2;
    font-weight: 400;
    border-radius: 6px;
    border-width: 1.5px;
    border-color: $baliHai;
    &--error {
      border-color: $torchRed;
      color: $torchRed;
      svg {
        path {
          fill: $torchRed;
        }
      }
    }

    &--warning {
      border-color: $flamenco;
      color: $flamenco;
      svg {
        path {
          fill: $flamenco;
        }
      }
    }

    &--success {
      svg {
        path {
          fill: $mountainMeadow;
        }
      }
      border-color: $mountainMeadow;
      color: $mountainMeadow;
    }
  }

  &__error-pargraph {
    height: 1rem;
    margin: 0.5rem 0;
    padding-bottom: 0.75rem;
    font-size: 12px;
    color: $red;
    text-align: center;
  }

  &__submit-button {
    width: 100%;
    border-radius: 4px !important;
    font-size: 18px !important;
    line-height: 1;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    background-color: $persianInigo;

    &:hover {
      background-color: $persianBlue !important;
    }
    &:active {
      background-color: $dogerBlue !important;
    }

    &:disabled {
      background-color: $athensGray;
      outline: none;
      border: none;
      color: $heather;

      &:hover {
        background-color: $athensGray !important;
      }
    }
  }

  &__buttons {
    margin-top: 2rem;

    button {
      font-size: 18px;
      width: 100%;
    }
  }
}
