.assessment-list {
  list-style: none;
  display: flex;
  margin-top: 3%;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 7%;

  &__item,
  &__items--container {
    font-size: 20px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__items--container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      gap: 15%;
      justify-content: flex-start;
    }
    align-items: center;
    width: 55%;
    &--sm {
      align-items: center;
      width: 30%;
      display: flex;
      gap: 15%;
      justify-content: space-between;

      .items--container__item {
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  &__item {
    &:nth-of-type(1) {
      margin-left: 2%;
      width: 15%;
    }
    &:nth-of-type(2) {
      min-width: 20%;

      @media (max-width: 768px) {
        min-width: 20%;
      }
    }

    &:nth-of-type(3) {
      width: 40%;
      @media (max-width: 768px) {
        width: 48%;
      }
    }
  }
}
