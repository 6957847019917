@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";

.create-suggestion {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  flex-direction: column;
  color: $steelGray;

  overflow: auto;

  .additional-informations__section {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .additional-information {
      @media (max-width: 768px) {
        width: 100%;
      }
      &__input {
        border-radius: 6px;
        height: 2.3rem;
        width: 100%;

        &--bg {
          border-color: $baliHai;
          height: 10vh;
          padding: 9px 0 70px 9px;
        }
      }

      width: 30%;
      color: $steelGray;

      &:nth-of-type(2) {
        display: flex;
        flex-direction: column;
      }
      &:nth-of-type(3) {
        label {
          margin-right: 0.5rem;
        }
      }

      &__radio-group {
        margin: 0.75rem 0;
        display: flex;
        align-items: center;

        label {
          margin-right: 1rem;
        }
        .radio {
          margin-right: 0.25rem;
        }
      }
      &__label {
        font-size: 16px;
        color: $steelGray;
        span {
          color: $torchRed;
        }
      }
      .select {
        margin-top: 0.5rem;
        width: 100%;
        border-radius: 6px !important;

        span {
          display: flex;
          align-items: center;
        }
        &__placeholder {
          display: flex;
          align-items: center;
        }
      }
      margin-top: 1rem;
    }

    .additional-information--customized {
      @media (max-width: 768px) {
        width: 100%;
      }
      &__input {
        border-radius: 6px;
        height: 2.3rem;
        width: 100%;

        &--bg {
          border-color: $baliHai;
          height: 10vh;
          padding: 9px 0 70px 9px;
        }
      }

      width: 45%;
      color: $steelGray;

      &:nth-of-type(2) {
        display: flex;
        flex-direction: column;
      }
      &:nth-of-type(3) {
        label {
          margin-right: 0.5rem;
        }
      }

      &__radio-group {
        margin: 0.75rem 0;
        display: flex;
        align-items: center;

        label {
          margin-right: 1rem;
        }
        .radio {
          margin-right: 0.25rem;
        }
      }
      &__label {
        font-size: 16px;
        color: $steelGray;
        span {
          color: $torchRed;
        }
      }
      .select {
        margin-top: 0.5rem;
        width: 100%;
        border-radius: 6px !important;

        span {
          display: flex;
          align-items: center;
        }
        &__placeholder {
          display: flex;
          align-items: center;
        }
      }
      margin-top: 1rem;
    }
  }

  &__section {
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 6px;
    margin-top: 1.5%;
    &--uploads {
      margin-top: 1%;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &--selects {
      margin-top: 0.5%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .selects__section {
        width: 30%;
        display: flex;
        @media (max-width: 768px) {
          width: 100%;
        }
        flex-direction: column;

        .label {
          font-size: 16px;
          color: $steelGray;
          margin-bottom: 0.25%;
          span {
            color: $torchRed;
          }
        }
      }
      .select {
        border-radius: 6px !important;
        width: 100%;

        span {
          display: flex;
          align-items: center;
        }
        &__placeholder {
          display: flex;
          align-items: center;
        }
      }
    }
    &__input {
      border-radius: 6px;
      height: 2.3rem;
      width: 45%;
      border-color: $baliHai;
      @media (max-width: 768px) {
        width: 100%;
      }
      &--error {
        border-color: $torchRed;
        border-radius: 6px;
        height: 2.3rem;
        width: 45%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    &--textareas {
      display: flex;
      justify-content: space-between;
      height: fit-content;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .textarea__section {
        width: 30%;
        @media (max-width: 768px) {
          width: 100%;
        }
        display: flex;
        flex-direction: column;

        .textarea {
          &--error {
            border-color: $torchRed;
            padding: 9px 0 180px 14px;
            @media (max-width: 768px) {
              padding: 9px 0 170px 14px;
            }
            width: 100%;
            border-radius: 6px;
            height: 23vh;
          }
          @media (max-width: 768px) {
            padding: 9px 0 130px 14px;
          }
          width: 100%;
          border-radius: 6px;
          height: 23vh;
        }
      }
    }

    &--labels {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--wider {
        margin-top: 1.5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          width: 30%;
        }
      }

      p {
        font-size: 12px;
        color: $torchRed;
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
      }
    }

    h4 {
      font-weight: 700;

      font-size: 24px;
      margin-bottom: 0.5rem;
    }
    hr {
      border-radius: 2px;
      border: 1px solid $anakiwa;
    }

    padding: 1rem 1.1rem 1.5rem 1.1rem;
    @include standard-box-shadow();
    width: 96%;
    min-height: 25%;
    max-height: 75%;
    &:nth-of-type(2) {
      flex: 1;
      min-height: 45%;
      max-height: 200%;
    }
    &:nth-of-type(3) {
      margin-bottom: 3%;
    }
  }
  &__header {
    &--mobile {
      display: none;
      background-color: $white;
      @media (max-width: 768px) {
        display: block;
        height: 20%;
      }
      .buttons-section {
        .create-suggestion__header__reset-button {
          font-weight: 500;
          width: 100%;
          height: 40%;
          border-radius: 4px !important;
          line-height: 1;
          font-size: 18px !important;
          background-color: $white !important;
          color: $persianInigo !important;
          border-color: $persianInigo;
        }
        .create-suggestion__header__sbmt-button {
          font-weight: 500;
          &:disabled {
            background-color: $athensGray !important;

            &:hover {
              background-color: $athensGray !important;
            }
          }

          width: 100%;
          height: 40%;
          border-radius: 4px !important;
          line-height: 1;
          font-size: 18px !important;
          font-weight: 500 !important;
          background-color: $persianInigo !important;

          &:hover {
            filter: brightness(80%);
          }
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 12px;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
    display: flex;
    align-items: center;
    padding: 0 2%;
    justify-content: space-between;
    height: 8%;

    h3 {
      font-weight: 700;
      color: black;
      margin-bottom: 0;
      font-size: 28px;
    }
    .buttons-section {
      width: 30%;
      display: flex;
      font-family: "Roboto", sans-serif;

      .create-suggestion__header__reset-button {
        font-weight: 500;
        width: 40%;
        border-radius: 4px !important;
        line-height: 1;
        text-align: center;
        font-size: 18px !important;
        background-color: $white !important;
        color: $persianInigo !important;
        border-color: $persianInigo;
        margin-right: 4%;
      }
      .create-suggestion__header__sbmt-button {
        font-weight: 500;
        &:disabled {
          background-color: $athensGray !important;

          &:hover {
            background-color: $athensGray !important;
          }
        }

        width: 40%;
        border-radius: 4px !important;
        line-height: 1;
        font-size: 18px !important;
        font-weight: 500 !important;
        background-color: $persianInigo !important;

        &:hover {
          filter: brightness(80%);
        }
      }
    }

    @include standard-box-shadow();
    width: 100%;
    background-color: $white;
  }
}
.error {
  &:first-of-type {
    margin-top: 0.25%;
  }
  font-family: "Roboto", sans-serif;
  width: 100%;
  font-size: 14px;
  margin-top: 1%;
  display: block;
  color: $torchRed;
}
.label {
  flex-grow: 1;
  font-size: 16px;
  color: $steelGray;
  span {
    color: $torchRed;
  }
}
.upload {
  width: 100%;
  min-height: 15%;
  max-height: 35%;
  &__file {
    &--names {
      display: flex;
      gap: 2%;
      width: 50%;
      align-items: center;
    }
    padding: 8px 10px;
    border-radius: 6px;
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $steelGray;
    font-size: 14px;
    min-height: 27px;
    max-height: 50px;
    background-color: $pattensBlue;
  }
  &__button {
    background-color: $malibu;
    border: none !important;
    padding-top: 8px;
    border-radius: 6px;
    margin-top: 1.5%;
    width: 24.15vw !important;
    @media (max-width: 768px) {
      margin-top: 3%;
      width: 87vw !important;
    }
    @media (min-width: 1900px) {
      margin-top: 1.5%;
      width: 25.5vw !important;
    }
  }
}
