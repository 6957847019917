@import "../../../assets/styles/variables.module.scss";

.user-ranking-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2rem 0;
  &__header {
    font-size: 24px;
    font-weight: 700;
    color: $riverBed;
  }

  &__info {
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    align-items: center;
    justify-content: space-between;
  }

  &__points {
    height: 100%;
    @media (max-width: 768px) {
      width: 90%;
      margin-right: 7%;
    }
    width: 25%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
  }

  &__ranking {
    height: 100%;
    width: 73%;
    @media (max-width: 768px) {
      width: 90%;
      margin-top: 3%;
      margin-bottom: 3%;
      margin-right: 7%;
    }
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: $white;
  }
}

.header {
  font-size: 17px;
  font-weight: 700;
  color: $riverBed;
  margin-bottom: 2rem;
}

.score {
  & > * {
    font-size: 120px;
    font-weight: 700;
  }
  height: 100%;
  min-height: 220px;
  color: $riverBed;
  margin-top: 1rem;
  border: 1px solid $pattensBlue;
  border-radius: 12px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.positions-container {
  width: 100%;
  border: 1px solid $pattensBlue;
  border-radius: 12px;
  min-height: 220px;
  height: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  .positions-header {
    @media (max-width: 768px) {
      display: none;
    }
    font-size: 17px;
    font-weight: 700;
    color: $riverBed;
    width: 100%;
    padding-top: 1rem;
    padding-left: 0.625rem;
    box-sizing: border-box;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .positions {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}

.position {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid $pattensBlue;
  @media (max-width: 768px) {
    justify-content: space-evenly;
  }

  &.logged {
    font-weight: 700;
  }

  &:last-child {
    border-bottom: none;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    @media (max-width: 768px) {
      width: 30%;
    }
    & > * {
      font-size: 17px;
      padding-left: 2rem;
    }
  }

  &__user-name {
    margin-left: 2rem;
    & > * {
      font-size: 17px;
    }
  }

  &__user-position {
    @media (max-width: 768px) {
      margin-left: 5%;
    }
    margin-left: 2.25rem;
    & > * {
      font-size: 17px;
    }
  }

  &__dot {
    margin-left: 2.25rem;
    display: block;
    border-radius: 50%;
    background-color: $ceruleanBlue;
    width: 20px;
    height: 20px;
  }
}
