@import "../../../assets/styles/global.module.scss";
@import "../../../assets/styles/variables.module.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & > span {
        font-weight: 500;
        font-size: 28px;
        color: $mainColor;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        cursor: pointer;
      }
    }
  }

  &__context {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__section-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 100%;

      &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }

      &__bottom {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }
    }

    &__section-2 {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      &__bottom {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }
    }

    &__section-role {
      width: 100%;
      span {
        font-size: 16px;
        color: $riverBed;
        padding-left: 13px;
      }
    }

    &__section-error {
      margin-top: 1rem;
      width: 100%;

      &__message {
        padding-left: 13px;
        color: $red;
      }
    }

    &__input-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 42px;
      margin-bottom: 1rem;
    }

    &__checkbox-container {
      color: $riverBed;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 40px;
      max-height: 50px;
      margin-bottom: 1rem;
      padding-left: 0.75rem;

      .formLabel {
        font-size: 14px;
        margin-left: 0.5rem;
      }
    }

    &__date-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      &__header {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        & > span {
          font-weight: 400;
          font-size: 17px;
          color: $mainColor;
        }
      }

      &__selectors {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__select-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30%;
          min-height: 40px;
          max-height: 50px;
          position: relative;

          &::after {
            pointer-events: none;
            display: block;
            content: "";
            border: solid $riverBed;
            border-width: 0 1px 1px 0;
            padding: 5px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: rotate(45deg) translateY(-100%);
            -webkit-transform: rotate(45deg) translateY(-100%);
          }
        }
      }
    }

    &__select-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 42px;
      margin-bottom: 1rem;
      position: relative;

      &::after {
        pointer-events: none;
        display: block;
        content: "";
        border: solid $riverBed;
        border-width: 0 1px 1px 0;
        padding: 5px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: rotate(45deg) translateY(-100%);
        -webkit-transform: rotate(45deg) translateY(-100%);
      }
    }
  }

  &__footer {
    margin-top: 1.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    &__button-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      button {
        font-size: 18px;
        font-weight: 700;
        width: 8rem;
        margin: 0;
        margin-right: 1.125rem;
      }
    }
  }
}

.input {
  box-sizing: border-box;
  padding: 0.25rem 0.75rem;
  width: 100%;
  height: 100%;
  border: 1px solid #e0e6e9;
  border-radius: 12px;
  font-size: 16px;
  color: $riverBed;

  &::placeholder {
    color: $riverBed;
  }
}

.select {
  border-radius: 12px;
  outline: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid #e0e6e9;
  color: $riverBed;
  font-size: 16px;
  font-weight: 400;
  padding: 0.25rem 0.75rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
