@import "./variables.module.scss";

body {
  background-color: $white;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0,0,0,.65);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
textarea {
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

button {
  border: none;
  &:hover {
    cursor: pointer;
  }
}
input::placeholder {
  color: $manatee;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: initial;
  outline: none;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;
}
