.admin-page {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;

  background-color: #F6F7F9;

  .pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
  
    padding: 2px 6px 2px 6px;
    border-radius: 4px;

    font-size: 12px;
    line-height: 17px;

    background-color: #9CCEFC;

    &:hover {
      background-color: #FFF;
    }

    span {
      @media(max-width: 768px) {
        display: none;
      }
    }

    @media(max-width: 768px) {
      background-color: #DEEFFE;
    }
  }

  .status {
    padding: 4px 10px;
    border-radius: 12px;
    background-color: #BDDEFD;
  }
  
  .dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      padding-right: 2rem;

      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.7rem;
      background-color: #FFFFFF;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);

      @media (max-width: 912px) {
        padding-left: 4rem;
      }  
    }

    .container {
      display: flex;
      min-height: 100vh;
      padding-top: 1.5rem;

      gap: 16px;

      .content-section {
        width: 95%;

        display: flex;
        flex-direction: column;

        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .extended-table {
          display: flex;
          align-items: flex-start;

          .worker-mobile {
            display: flex;
            flex-direction: column;

            &__credentials {
              font-size: 14px;
            }

            &__amount {
              font-size: 16px;
              font-weight: 700;
            }
          }

          .points-mobile {
            display: flex;
            flex-direction: column;

            text-align: right;

            &__date {
              font-size: 14px;
            }

            &__points {
              font-size: 14px;
            }
          }

        .user-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 0;
          border-bottom: 1px solid #D9D9D9;

          .worker-mobile {
            display: none;

            @media(max-width: 575px) {
              display: flex;
            }
          }

          .points-mobile {
            display: none;

            @media(max-width: 575px) {
              display: flex;
            }

          }

          &__desktop {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .details-item {
              flex-grow: 1;
            }

            @media(max-width: 575px) {
              display: none;
            }
          }
        }
      
        table {
          min-height: 33vh;

          td:empty {
            padding: 0;
          }

          @media(max-width: 575px) {
            td {
              border-bottom: 1px solid #D9D9D9;
            }
          }
        }

        .table-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          gap: 12px;

          h3 {
            width: 50%;

            @media(max-width: 768px) {
              width: 100%;
            }
          }

          @media(max-width: 768px) {
            flex-direction: column;
          }
        }

        .table-search {
          max-width: 33%;

          @media(max-width: 768px) {
            max-width: 100%;
            width: 100%;
          }
        }

        .table-actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-grow: 1;
          gap: 12px;

          button {
            padding: 2px 6px;

            svg {
              margin: 0 4px;
            } 
          }

          .actions-cleaner {
            border: none;
          }

          @media(max-width: 768px) {
            justify-content: flex-start;
          }
        }

        .table-empty {
          color: #000;
        }

        .expand-icon {
          cursor: pointer;
          transform: rotate(90deg);
        }

        .table-navigation {
          width: 176px;
          border: none;
          margin-top: 8rem !important;

          @media(max-width: 768px) {
            width: 100%;
            margin-top: 0 !important;
          }

          li {
            cursor: pointer;

            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            &[role=separator] {
              border: 6px solid #F6F7F9;
              
              @media(max-width: 768px) {
                border: none;
              }
            }

            &[role=menuitem] {
              box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);

              @media(max-width: 768px) {
                box-shadow: none;
              }
            }

            @media(max-width: 768px) {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }

          @media(max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }
        
        @media (max-width: 768px) {
          flex-direction: column;
          gap: 12px;
        }
      }

        .report {
          padding: 1rem;
          padding-bottom: 2rem;
          border-radius: 6px;

          background-color: #FFF;

          &__container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            gap: 10px;

            margin-top: 1rem;

            @media (max-width: 768px) {
              flex-direction: column;
            }
          }

          .range-picker {
            margin-top: 0.5rem;
            width: 100%;
            height: 40px;

            max-width: 30%;

            border-radius: 6px !important;

            & > *:last-child {
              order: -1 !important;

              svg > path {
                fill: #8391AD !important;
              }
            }

            @media (max-width: 768px) {
              max-width: 100%;
            }
          }

          .select {
            margin-top: 0.5rem;
            width: 100%;
            height: 40px;

            max-width: 30%;

            border-radius: 6px !important;

            & > *:last-child {
              order: -1 !important;
            }
    
            span {
              display: flex;
              align-items: center;

            }
            &__placeholder {
              display: flex;
              align-items: center;

              svg > path {
                fill: #8391AD;
              }
            }

            @media (max-width: 768px) {
              max-width: 100%;
            }
          }

          .report-buttons {
            width: 90%;
            display: flex;
            justify-content: center;

            max-width: 30%;
            
            margin-top: 0.5rem;
            border-radius: 6px !important;

            svg {
              margin: 0 4px;
            }

            @media (max-width: 768px) {
              max-width: 100%;
              width: 100%;
            }
          }

          @media (max-width: 912px) {
            width: 90%;
          }
        }
          
        @media (max-width: 768px) {
          justify-content: center;
          align-items: center;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 768px) {
    min-height: 100vh;
  }
}
