@import "../../../assets/styles/variables.module.scss";

.user-info {
  background-color: $white;
  width: 100%;
  font-size: 18px;

  &__close {
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .context {
      color: $ceruleanBlue;
      font-size: 28px;
      font-weight: 500;
      margin: 0;
    }

    &__icon {
      cursor: pointer;

      padding: 0.25rem;
    }
  }

  &__container {
    color: $riverBed;
    margin-top: 2rem;

    &__details {
      display: flex;
      font-size: 18px;
      margin: 1rem 0;

      &__name {
        margin: 0;
        width: 30%;
        font-weight: 500;
      }

      &__value {
        line-break: anywhere;
        margin: 0;
        width: 70%;
      }
    }
  }
}
