@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/global.module.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: $riverBed;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 17%;
    width: 100%;

    &__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 90%;

      &__icon {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        background-color: $mainColor;
        margin-right: 5%;
        box-sizing: border-box;
        padding: 2%;
      }

      & > span {
        font-weight: 500;
        font-size: 28px;
        color: $riverBed;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        cursor: pointer;
      }
    }
  }

  &__context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 1.375rem;
    width: 100%;

    &__section-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;

      &__top {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 1.5rem;

        & > span {
          font-weight: 400;
          font-size: 16px;
          color: $riverBed;
        }
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 70%;
      }
    }

    &__input-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0.75rem;

        &.second {
          margin-top: 0.75rem;
        }

        & > span {
          font-weight: 400;
          font-size: 16px;
          color: $riverBed;
        }
      }
    }

    &__error-container {
      width: 100%;
      height: 1rem;
      margin: 1rem 0;
      .message {
        color: $red;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 17%;
    width: 100%;

    &__button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      &__button-back {
        margin-right: 1.25rem;
        padding: 0.625rem 1.25rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        font-weight: 500;
        border-radius: 12px;
        font-size: 18px;
        color: $mainColor;
        border: 1px solid $mainColor;
      }

      &__button-submit {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $mainColor;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 700;
        color: $white;
        padding: 0.625rem 1.25rem;
      }
    }
  }
}

.input {
  box-sizing: border-box;
  padding: 11px 12px;
  width: 100%;
  height: 100%;
  border: 1px solid #e0e6e9;
  border-radius: 12px;
  font-size: 16px;
  color: $riverBed;

  &::placeholder {
    color: $manatee;
  }
}

.error-message {
  height: 1rem;
  margin: 1rem 0;
  color: $red;
}
