@import "../../../../assets/styles/variables.module.scss";

.department-details {
  background: $white;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 85%;
  }

  .header {
    width: 100%;

    &__department {
      border-top: 1px solid $lineColor;
      border-bottom: 1px solid $lineColor;
      padding-left: 2.25rem;
      display: flex;

      &__breadcrumbs {
        display: flex;
      }

      &__edit {
        margin-left: 0.5rem;
        padding: 0.5rem;
        background: none;
      }
      &__delete {
        background: none;
      }
    }

    &__supervisors {
      display: flex;
      padding: 1rem 2.25rem;

      &__empty {
        color: $riverBed;
      }

      .supervisor {
        display: flex;
        align-items: center;

        &__details {
          margin-left: 2rem;

          &__name {
            font-size: 16px;
            color: $riverBed;
            margin: 0;
          }

          &__job-position {
            font-size: 14px;
            color: $riverBed;
            margin: 0;
          }
        }

        &:nth-child(1) {
          margin-right: 3rem;
        }
      }
    }
  }

  .job-position {
    box-sizing: border-box;
    max-height: 30%;
    overflow: scroll;

    &__header {
      width: 100%;
      background: $aliceBlue;
      border-top: 1px solid $lineColor;
      border-bottom: 1px solid $lineColor;

      &__btn {
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: 1rem 0;
        font-size: 16px;
        font-weight: 600;
        color: $lightBlue;
        background: none;
        text-align: center;

        svg {
          margin-left: 0.5rem;

          path {
            fill: $lightBlue;
          }
        }
      }
    }

    &__list {
      margin-top: 1rem;
      padding: 0 2.25rem;
    }
  }

  .departments {
    height: 100%;
    overflow-y: scroll;

    &__list {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 0 2.25rem;
      width: 100%;
      height: 100%;
      @media (max-width: 768px) {
        display: grid;
        max-height: 60%;
        overflow-y: scroll;
        overflow-x: none;
        grid-template-columns: 1fr;
        padding-bottom: 20%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
