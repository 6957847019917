@import "../../../../assets/styles/variables.module.scss";
@import "../../../../assets/styles/mixins.module.scss";

.top-bar {
  padding-top: 2.625rem;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  display: flex;
  justify-content: space-between;
  background-color: transparent;
  &__search-container {
    width: 100%;
    position: relative;
    .search-placeholder {
      position: absolute;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      white-space: nowrap;
      top: 50%;
      left: 0.75rem;

      display: flex;
      align-items: center;

      span {
        opacity: 0.5;
      }
    }

    .search-icon {
      width: 1.5rem;
      height: 1.5rem;
      path {
        fill: $kashmirBlue;
      }
      margin-right: 0.5rem;
    }
  }

  &__right-area {
    width: 15vw;

    &--bigger {
      display: flex;
      &__checkboxes {
        display: flex;
        label {
          width: 10vw;
          @media (max-width: 768px) {
            width: 28vw;
          }
        }
        align-items: center;
      }

      justify-content: space-between;
      align-content: center;

      @media (max-width: 768px) {
        width: 96vw;
        gap: 0.5rem;
        flex-direction: column;
      }
      input {
        padding-left: 25%;
        width: 100%;

        border-radius: 10px;
        border: none;
        height: 2.563rem;
        @media (max-width: 768px) {
          width: 96%;
          margin-right: 4%;
        }

        @include form-input-border($seashell);
        ::placeholder {
          color: $riverBed;
          font-size: 30px;
        }
      }
    }

    @media (max-width: 768px) {
      width: 96vw;
    }
    input {
      padding-left: 25%;
      width: 100%;
      border-radius: 10px;
      border: none;
      height: 2.563rem;
      margin-right: 0;
      @include form-input-border($seashell);
      ::placeholder {
        color: $riverBed;
        font-size: 30px;
      }
      @media (max-width: 768px) {
        width: 96%;
        margin-right: 4%;
      }
    }
  }

  &__assessment-header {
    margin: 0;
    color: $riverBed;
    font-size: 28px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 18px;
    }
    display: flex;
    align-items: center;
  }
}
