@import "../../../assets/styles/variables.module.scss";

.page {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;

  &__dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
      overflow-y: auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    overflow: auto;
  }
}

.topbar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25%;

  &.filter {
    justify-content: space-between;
  }
}

.switch-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  border-radius: 12px;
  width: 100%;
  max-width: 345px;
  background: $lavender;

  .button {
    font-size: 18px;
    color: $riverBed;
    padding: 0.5rem 1rem;
    flex: 1;
    background: none;
    border: none;
    font-weight: 700;
    margin: 3px 5px;
    transition: 0.3s;
    border-radius: 12px;

    &.active {
      box-shadow: 0 0 3px 0px #dadcde;
      background-color: $mainColor;
      color: $white;
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 100%;
    background-color: $white;
    position: relative;
    border-radius: 12px;
    box-shadow: 0 0 3px 0px #dadcde;

    &::before {
      content: "";
      display: block;
      background-color: $mainColor;
      width: 33%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      border-radius: 12px 0 0 12px;
      transition: 0.3s;
    }
    &.isAdmin::before {
      left: 30%;
      border-radius: 0 12px 12px 0;
    }

    &__element {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33%;
      height: 100%;
      z-index: 1;
      cursor: pointer;

      & > span {
        font-weight: 500;
        font-size: 18px;
        color: $mainColor;
        transition: 0.3s;
      }

      & > span.active {
        color: $white;
      }
    }
  }
}
