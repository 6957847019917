@import "../../../../assets/styles/variables.module.scss";
@import "../../../../assets/styles/mixins.module";

.assessments-container {
  display: flex;
  flex-direction: column;
  .assessments-list {
    display: flex;
    flex-grow: 1;
    list-style: none;
    flex-direction: column;

    gap: 1rem;
    padding-left: 0;

    &__item--rates,
    &__item {
      display: flex;
      font-size: 18px;
      justify-content: space-between;
      align-items: center;
      height: 4rem;
      border-radius: 12px;
      border: 1px solid $seashell;
    }
    &__item--rates {
      padding-left: 2%;

      .review-link {
        color: $mainFontColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: 768px) {
          margin-right: 15%;
        }

        .rates__container {
          width: 20%;

          @media (max-width: 768px) {
            width: 45%;
            margin-right: 0;
          }

          padding-left: 4%;
          justify-content: space-between;
          display: flex;
        }
      }

      @media (max-width: 768px) {
        font-size: 10px;
      }

      &:hover {
        cursor: pointer;
      }
      span {
        width: 15%;
        white-space: nowrap;
        @media (max-width: 768px) {
          &:first-of-type {
            width: 30%;
          }
          &:nth-of-type(3) {
            width: 5%;
          }
        }
      }
    }
    &__item {
      &--buttons-container {
        @media (max-width: 768px) {
          width: 42%;
        }

        display: flex;
        justify-content: center;
        width: 45%;
        button {
          padding: 0.75rem 1rem;
          border-radius: 12px;
          font-size: 16px;
          background-color: $white;
          font-weight: 400;
          color: $mainColor;
          &:hover {
            background-color: $mainColor;
            border: none;
            color: $white;
          }
          @include form-input-border($mainColor);
          @media (max-width: 768px) {
            padding: 0.25rem 0.5rem;
            font-size: 10px;
          }
          &:nth-of-type(2) {
            margin-left: 1.5%;
          }
        }
      }
      @media (max-width: 768px) {
        font-size: 10px;
      }
      span {
        &:nth-of-type(1) {
          margin-left: 2%;
          width: 15%;
        }
        &:nth-of-type(2) {
          min-width: 20%;

          @media (max-width: 768px) {
            min-width: 23%;
          }
        }

        &:nth-of-type(3) {
          width: 28%;
          @media (max-width: 768px) {
            width: 32%;
          }
        }
        &:nth-of-type(4) {
          width: 5%;
          @media (max-width: 768px) {
            width: 7%;
          }
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 2rem;

    button {
      width: 6rem;
      height: 2.6rem;

      margin: 0.5rem;
      padding: 0.5rem;
      border-radius: 12px;

      font-weight: 700;
      font-size: 16px;
      background-color: $mainColor;
      color: $white;

      &[disabled] {
        filter: grayscale(1);
      }
    }
  }
}
