@import "../../../../assets/styles/variables.module.scss";

.dashboard {
  background-color: $mainBackground;
  box-sizing: border-box;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &.selected {
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }

  &__supervisor {
    padding: 0;
    background: $white;
    width: 100%;
    border: 1px solid $lineColor;
    border-radius: 12px;

    &__department {
      box-sizing: border-box;
      background: white;
      width: 100%;
      flex: 1;
      display: flex;

      flex-direction: column;
      justify-content: center;

      margin: 1rem 0.66%;
      align-items: center;
      border-radius: 12px;
      border: 1px solid $lineColor;
      cursor: pointer;

      &.supervisor {
        margin: 0;
        border: none;

        .department__icon {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }

        .department__name {
          padding-bottom: 0.5rem;
        }
      }
    }

    .department__icon {
      padding-top: 3rem;
      padding-bottom: 1.25rem;
    }

    .department__name {
      font-size: 16px;
      color: $riverBed;
      padding-bottom: 2rem;
      text-align: center;
      word-break: break-all;
    }
  }

  &__areas {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
    }
    list-style: none;
    padding: 0;
  }

  &__departments {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
    }
    list-style: none;
    padding: 0;
    overflow-y: scroll;
    height: 70vh;
  }

  &__selected-departments {
    display: flex;
  }
}
