@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";
.table {
  height: 75%;
  width: 100%;
  background-color: $white;
  border-radius: 6px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;

  @media (max-width: 768px) {
    height: auto;
    width: 87.5vw;
  }

  @include standard-box-shadow();

  &__body {
    @media (max-width: 768px) {
      display: none;
    }
    width: 100%;
    height: 75%;

    &__rows {
      width: 100%;

      min-height: 85%;
      max-height: 90%;
    }

    &__header {
      @media (max-width: 768px) {
        display: none;
      }

      height: 10%;
      padding-left: 2%;
      background-color: $anakiwa;
    }
  }
  &__header {
    .header__assessments__pagination {
      margin-bottom: 10%;
      display: none !important;
      @media (max-width: 768px) {
        display: flex !important;
      }
      list-style: none;

      .next-btn,
      .previous-btn {
        display: flex;
        align-content: center;
        border-radius: 6px;
        background-color: $pattensBlue;
        padding: 12px 16px;
        color: $black;
      }
      .next-btn {
        margin-left: 10%;
      }

      li > .pagination-link {
        padding: 12px 16px;
        display: inline-block;
        &--active {
          color: $steelGray;
          background-color: $anakiwa;
        }
        cursor: pointer;
        background-color: $white;
        border-radius: 6px;
        min-width: 2rem;
      }

      .pagination-link {
        color: $baliHai;
      }
      padding: 0;
    }
    .header__assessments-list {
      display: none;

      .assessment {
        display: flex;
        flex-direction: column;
        margin-top: 1.375rem;
        &__type--poisitive,
        &__type--negative {
          border-radius: 12px;
          font-size: 14px;

          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }

        &__break {
          display: none;
          @media (max-width: 768px) {
            display: block;
          }
          :nth-of-type(4) {
            display: none;
          }

          width: 100%;
          height: 1px;
          background-color: $alta;
        }
        &__section {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .section {
            display: flex;
            flex-direction: column;
            width: 50%;
            font-family: "Roboto";
            color: $steelGray;
            &__header {
              font-size: 14px;
              font-weight: 700;
            }
            &__content {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        &__type--poisitive {
          color: $steelGray;
          background-color: $anakiwa;
        }

        &__type--negative {
          background-color: $persianBlue;
          color: $white;
        }
        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        display: block;
        padding: 0 1.5% 0 0;
        height: 100%;
        overflow-y: auto;
        height: auto;
      }
    }
    @media (max-width: 768px) {
      padding-left: 4%;
      height: auto;
      overflow-y: auto;
      flex-direction: column;
    }

    .hr {
      display: none;
      @media (max-width: 768px) {
        display: block;
        margin-top: 0.5rem;
      }

      width: 100%;
      height: 1px;
      background-color: $anakiwa;
    }
    &--filter-section {
      width: auto;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }

      .checkbox__section {
        margin-right: 7%;
        @media (max-width: 768px) {
          margin-top: 3%;
        }
        width: 100%;
        .checkbox {
          margin-right: 3%;
        }
        display: flex;
      }
      span {
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        margin-right: 5%;
      }
      display: flex;
      justify-content: space-between;

      align-items: center;

      .input {
        width: 100%;
        @media (max-width: 768px) {
          width: 100%;
          margin-left: 3%;
        }
        border-radius: 6px;
        border: 1.5px solid $baliHai;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
    padding: 0 1.5%;
    height: 12%;
    display: flex;
    justify-content: space-between;

    align-items: center;

    h3 {
      margin-bottom: 0;
      font-size: 24px;
      font-family: "Roboto", sans-serif;
      color: $steelGray;
      width: auto;
      font-weight: 700;
    }
  }
  &__pagination {
    margin: 0;
    @media (max-width: 768px) {
      display: none;
    }
    list-style: none;
    display: flex;

    padding-left: 2rem;

    .next-btn,
    .previous-btn {
      display: flex;
      align-content: center;
      border-radius: 6px;
      background-color: $anakiwa;
      .icon {
        margin-top: 3%;
      }
      color: $black;
    }

    li > .pagination-link {
      padding: 12px 16px;
      display: inline-block;
      &--active {
        color: $steelGray;
        background-color: $anakiwa;
      }
      cursor: pointer;
      background-color: $white;
      border-radius: 6px;
      min-width: 2rem;
    }

    .pagination-link {
      color: $baliHai;
    }

    padding-right: 2rem;
  }
}
.checkbox {
  color: $baliHai;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.spinner {
  margin-left: 165%;
  margin-top: 50%;
}

.icon {
  &:hover {
    cursor: pointer;
  }
  margin-top: 3%;
}
