@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";
.page {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  @media (max-width: 768px) {
    margin: 0;
  }

  &__dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .container {
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        margin-left: 0;

        overflow-y: auto;
      }
      height: 100%;
      margin: 29px 0 0 32px;
      display: flex;
      gap: 1%;
      &__rates-section {
        width: 23%;
        @media (max-width: 768px) {
          width: 100%;
        }
        font-family: "Roboto", sans-serif;
        color: $white;
        height: 100%;

        .rate {
          @media (max-width: 768px) {
            height: 40%;
          }
          position: relative;
          &__icon {
            @media (max-width: 768px) {
              height: 70%;
              width: 70%;
              top: 30%;
              left: 55%;
            }
            position: absolute;
            left: 58%;
            top: 27%;
          }
          &__header {
            &--bg {
              font-weight: 700;
              font-size: 58px;
              @media (max-width: 768px) {
                font-size: 40px;
              }
            }
            font-size: 20px;
          }
          height: 25%;
          width: 100%;
          padding-left: 23px;
          padding-top: 23px;
          border-radius: 8.57px;
          &:nth-of-type(2) {
            background-color: $persianInigo;
          }
          background-color: $dogerBlue;
        }
      }

      &__table-section,
      &__cards-section {
        width: 73%;
      }

      &__cards-section {
        .card {
          &:nth-of-type(3) {
            margin-top: 3%;
          }
          &__textarea {
            margin-bottom: 3%;
            border: 1.5px solid $steelGray;
            border-radius: 6px;
          }
          &__links {
            list-style: none;
            height: 10%;
            overflow-y: auto;
            padding: 0;
            .link {
              display: flex;
              align-items: center;
              padding: 0% 1%;
              justify-content: space-between;
              background-color: $pattensBlue;
              border-radius: 6px;
              span {
                width: 12%;
                justify-content: space-between;
                display: flex;
                align-items: center;
              }

              a {
                color: $steelGray;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
              }
            }
          }
          min-height: 25%;
          border-radius: 6px;
          width: 80%;
          max-height: 75%;
          padding: 1rem 1.1rem 1.5rem 1.1rem;
          &__footer {
            margin-bottom: auto;
            display: flex;
            justify-content: flex-end;
          }
          &__button {
            min-width: 15%;
            max-width: 18%;

            font-weight: 500;
            &:disabled {
              background-color: $athensGray !important;

              &:hover {
                background-color: $athensGray !important;
              }
            }

            height: 40%;
            border-radius: 4px !important;
            line-height: 1;
            font-size: 18px !important;
            font-weight: 500 !important;
            background-color: $persianInigo !important;

            &:hover {
              filter: brightness(80%);
            }
          }

          &:nth-of-type(2) {
            margin-top: 2%;
            margin-left: 21%;
            h2 {
              margin: 0;
            }
          }
          hr {
            border-radius: 2px;
            border: 1px solid $anakiwa;
          }

          &__icon {
            cursor: pointer;
          }

          &__section {
            margin-top: 3%;
            margin-bottom: 3%;
            overflow-y: auto;

            &--edit {
              margin-bottom: 1%;
            }
          }
          &__header {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            &__date {
              color: $baliHai;
            }
            &__info {
              width: 50%;
            }
            .positive__info,
            .negative__info {
              padding: 0.5% 0.75%;
              width: auto;
              border-radius: 12px;
              width: auto;
              text-align: center;
              font-size: 14px;
              display: inline-block;
            }

            .positive__info {
              color: #2d273a;
              background-color: #9ccefc;
            }

            .negative__info {
              color: #ffffff;
              background-color: #2123b3;
            }

            span {
              display: flex;
              align-items: center;
            }
            h2 {
              margin: 0 2%;
              display: inline-block;
              font-size: 24px;
              font-weight: 700;
              color: $black;
              font-family: "Roboto", sans-serif;
            }
          }
          @include standard-box-shadow();
        }
      }
    }

    @media (max-width: 768px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
