@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";

.error {
  &:first-of-type {
    margin-top: 0.25%;
  }
  font-family: "Roboto", sans-serif;
  width: 100%;
  font-size: 14px;
  margin-top: 1%;
  display: block;
  color: $torchRed;
}

.upload {
  width: 100%;
  min-height: 15%;
  max-height: 35%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 0.5rem;

  &__file {
    &--names {
      display: flex;
      gap: 2%;
      width: 50%;
      align-items: center;
    }

    padding: 8px 10px;
    border-radius: 6px;
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $steelGray;
    font-size: 14px;
    min-height: 27px;
    max-height: 50px;
    background-color: $pattensBlue;
  }

  &__button {
    background-color: $malibu;
    border: none !important;
    padding-top: 8px;
    border-radius: 6px;
    margin-top: 1.5%;
    width: 55vw !important;

    @media (max-width: 768px) {
      margin-top: 3%;
    }
    @media (min-width: 1900px) {
      margin-top: 1.5%;
    }

    &:hover {
      background-color: $malibu;
      filter: brightness(80%);
    }
  }
}

.evaluate {
  &__comments {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: $riverBed;

    &__header {
      padding-left: 1.25rem;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }

    &__comment {
      margin-top: 1rem;
      padding: 0.5rem 1.25rem 0.5rem 1.25rem;
      border: 1px solid $pattensBlue;
      border-radius: 12px;

      &__date {
        margin: 0;

        .bold {
          font-weight: 500;
        }
      }

      &__author {
        margin: 0;

        .bold {
          font-weight: 500;
        }
      }
    }
  }

  &__consult {
    margin-top: 1rem;

    &__header {
      font-size: 18px;
      font-weight: 500;
    }

    &__cost {
      display: flex;
      text-align: center;
      align-items: baseline;
      padding-bottom: 0.5rem;

      border: 1px solid $pattensBlue;
      border-radius: 12px;
      background: $linkWater;
    
      .cost-label {
        width: 35%;
        color: $riverBed;
        font-size: 18px;
        font-weight: 500;
      }
    
      .cost-input {
        width: 35%;
        font-size: 14px;
        margin-top: 0.5rem;
        border: 1px solid $pattensBlue;
        border-radius: 10px;
        padding: 0.5rem;
      }
    }

    &__decision {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      font-family: 'Roboto', sans-serif;

      .description {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        margin-left: 2%;
        margin-right: 2%;
        border-radius: 6px;
        margin-top: 1.5%;
        padding: 1rem 1.1rem 1.5rem 1.1rem;
        box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.05);
        width: 96%;

        .paragraph {
          &__header {
            font-weight: 700;
          }
        }
      }

      .warning-not-corrected {
        display: flex;
        width: 100%;

        margin-bottom: 0.75rem;

        border: 1px solid $pattensBlue;
        border-radius: 6px;
        background: $linkWater;
        padding: 0.75rem 1.25rem;
        font-size: 18px;
        font-weight: 500;
        
        color: $red;
  
        &__text {
          text-align: center;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  &__comments-section {
    margin-top: 1.5rem;

    .header {
      margin: 0;
      padding-left: 1.25rem;
      color: $riverBed;
      font-size: 22px;
      font-weight: 500;
    }

    .comment {
      border: 1px solid $pattensBlue;
      border-radius: 6px;
      background: $linkWater;
      padding: 1rem 1.25rem;
      color: $riverBed;
      font-size: 18px;

      &:nth-child(n + 2) {
        margin-top: 1rem;
      }

      &__author {
        margin: 0;

        &__header {
          font-weight: 500;
        }
      }

      &__date {
        margin: 0;

        &__header {
          font-weight: 500;
        }
      }

      &__content {
        margin: 0;
      }

    }
  }

  &__select {
    margin-top: 2rem;
    padding: 0 1.25rem;

    &__header {
      .text {
        font-size: 22px;
        font-weight: 500;
        color: $riverBed;
      }
    }

    &__container {
      margin-top: 1rem;
      display: flex;
      align-items: center;

      .icon-button {
        border: 1px solid $pattensBlue;
        background: none;
        border-radius: 10px;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;

        svg {
          background: $white;

          path {
            fill: $pattensBlue;
            stroke: $pattensBlue;
          }
        }

        &.accept {
          svg {
            path {
              fill: $white;

              stroke: $pattensBlue;
            }
          }
        }

        &.active {
          background: $ceruleanBlue;
          svg {
            background: $ceruleanBlue;

            path {
              stroke: $white;
              fill: $ceruleanBlue;
            }
          }
        }
      }
    }
  }

  &__comment {
    padding: 0 1.25rem;

    &__header {
      font-weight: 500;
      font-size: 22px;
      color: $riverBed;
      margin: 2rem 0;
    }

    &__paragraph {
      margin: 0 0 0.75rem 0;
      color: $riverBed;
      font-size: 17px;
      font-weight: 500;
    }

    &__text-areas {
      display: flex;
      flex-direction: column;

      .text-areas--big {
        padding: 0.5rem 0.5rem 0 0.5rem;
        width: 100%;
        height: 7.75rem;
        resize: none;
        border-radius: 10px;
        border: 1px solid $pattensBlue;
      }

      .text-areas__error {
        margin-top: 1rem;
        color: $red;
      }
    }
  }

  .buttons-section {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 12px;

    .secondary-button {
      font-weight: 500;
      background-color: $white !important;
      color: $persianInigo !important;
      border-color: $persianInigo;
      border-radius: 4px;
    }

    .primary-button {
      &:disabled {
        background-color: $athensGray !important;

        &:hover {
          background-color: $athensGray !important;
        }
      }

      font-weight: 500 !important;
      background-color: $persianInigo !important;
      border-radius: 4px;

      &:hover {
        filter: brightness(80%);
      }
    }
  }

  &__section {
    border-radius: 6px;

    &--uploads {
      margin-top: 1%;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .redirection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      margin-top: 0.5%;
      width: 100%;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .select {
        margin-top: 0.5rem;
        width: 100%;
        height: 40px;

        border-radius: 6px !important;

        & > *:last-child {
          order: -1 !important;
        }

        span {
          display: flex;
          align-items: center;

        }
        &__placeholder {
          display: flex;
          align-items: center;

          svg > path {
            fill: #8391AD;
          }
        }

        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }

    &__input {
      border-radius: 6px;
      height: 2.3rem;
      width: 45%;
      border-color: $baliHai;
      @media (max-width: 768px) {
        width: 100%;
      }

      &--error {
        border-color: $torchRed;
        border-radius: 6px;
        height: 2.3rem;
        width: 45%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    &--textareas {
      display: flex;
      justify-content: space-between;
      height: fit-content;
      gap: 0.5rem; 

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .textarea__section {
        width: 100%;
        @media (max-width: 768px) {
          width: 100%;
        }
        display: flex;
        flex-direction: column;

        .textarea {
          &--error {
            border-color: $torchRed;
            padding: 9px 0 180px 14px;
            @media (max-width: 768px) {
              padding: 9px 0 170px 14px;
            }
            width: 100%;
            border-radius: 6px;
            height: 23vh;
          }
          @media (max-width: 768px) {
            padding: 9px 0 130px 14px;
          }
          width: 100%;
          border-radius: 6px;
          height: 23vh;
        }
      }
    }

    &--labels {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--wider {
        margin-top: 1.5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          width: 100%;
        }
      }

      p {
        font-size: 12px;
        color: $torchRed;
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
      }
    }

    h4 {
      margin-bottom: 0.5rem;
      font-weight: 700;
      font-size: 24px;
      color: black;
    }

    hr {
      border-radius: 2px;
      border: 1px solid $anakiwa;
    }

    padding: 0.75rem;
    
    min-height: 25%;
    max-height: 75%;

    &:nth-of-type(2) {
      flex: 1;
      min-height: 45%;
      max-height: 200%;
    }

    &:nth-of-type(3) {
      margin-bottom: 3%;
    }
  }
}
