@import "../../../assets/styles/variables.module.scss";

.top-bar {
  padding-top: 2.625rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;

  @media (max-width: 768px) {
    justify-content: space-evenly;
    align-items: center;
  }

  align-items: center;

  &__left {
    display: flex;

    .header {
      margin: 0;
      color: $riverBed;
      font-size: 28px;
      font-weight: 700;
      min-width: 350px;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        min-width: 0;
      }
    }
  }

  .switch-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    border-radius: 12px;
    width: 100%;
    max-width: 345px;
    background: $lavender;

    &.user {
      background: none;
    }

    .button {
      font-size: 18px;
      color: $riverBed;
      padding: 0.625rem 1.5rem;
      flex: 1;
      background: none;
      border: none;
      font-weight: 700;
      margin: 3px 0;
      transition: 0.3s;
      border-radius: 12px;

      &.active {
        box-shadow: 0 0 3px 0px #dadcde;
        background-color: $mainColor;
        color: $white;
      }
    }

    &__switch {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      height: 100%;
      background-color: $white;
      position: relative;
      border-radius: 12px;
      box-shadow: 0 0 3px 0px #dadcde;

      &::before {
        content: "";
        display: block;
        background-color: $mainColor;
        width: 33%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        border-radius: 12px 0 0 12px;
        transition: 0.3s;
      }
      &.isAdmin::before {
        left: 30%;
        border-radius: 0 12px 12px 0;
      }

      &__element {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33%;
        height: 100%;
        z-index: 1;
        cursor: pointer;

        & > span {
          font-weight: 500;
          font-size: 18px;
          color: $mainColor;
          transition: 0.3s;
        }

        & > span.active {
          color: $white;
        }
      }
    }
  }

  .topbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .filter {
      margin-right: 3rem;

      @media (max-width: 768px) {
        margin-right: 3rem;
      }
    }

    .btn-group {
      margin-right: 2rem;
    }
  }
}

.btn {
  border: 1px solid $pattensBlue;
  color: $riverBed;
  font-size: 18px;
  padding: 0.625rem;
  background: $white;
  border-radius: 12px;
}
