@import "../../../assets/styles/variables.module.scss";

.submit-button {
  width: 9.875rem;
  height: 2.438rem;
  background-color: $ceruleanBlue;
  color: $white;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  font-size: 17px;
  @media (max-width: 768px) {
    margin-top: 3%;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #fcfcff;
    border: 1px solid #e0e6e9;
    color: $riverBed;
  }
}

.manager-button {
  @media (max-width: 768px) {
    margin-right: 15%;
  }
}

.send-suggestion {
  width: 10.188rem;
  height: 2.563rem;
  font-size: 16px;
  font-weight: 700;
  background-color: $mainColor;
  color: $white;
  border-radius: 12px;
  border: none;
  @media (max-width: 768px) {
    margin-right: 15%;
    height: 3.5rem;
    width: 10rem;
  }

  @media (max-width: 280px) {
    width: 9rem;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #fcfcff;
    border: 1px solid #e0e6e9;
    color: $riverBed;
  }
}

.button {
  width: 10.188rem;
  height: 2.563rem;
  font-size: 15px;
  font-weight: 700;
  background-color: $mainColor;
  color: $white;
  border-radius: 12px;
  border: none;

  @media (max-width: 280px) {
    height: 3rem;
    width: 6rem !important;
    font-size: 16px;
  }
  @media (max-width: 768px) {
    margin-right: 0;
    height: 3rem;
    width: 10rem;
    font-size: 16px;
  }
  @media (max-width: 280px) {
    height: 3rem;
    width: 7rem;
    font-size: 16px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #fcfcff;
    border: 1px solid #e0e6e9;
    color: $riverBed;
  }
}

.cancel {
  font-size: 18px;
  font-weight: 500;
  color: $ceruleanBlue;
  border-radius: 12px;
  border: 1px solid $ceruleanBlue;
  background-color: $white;
  width: 9.875rem;
  height: 2.438rem;
}

.no-btn {
  max-width: 90%;
  font-size: 18px;
  font-weight: 500;
  color: $rockBlue;
  border-radius: 12px;
  border: 1px solid $mainColor;
  background-color: $white;
  padding: 0.75rem;
  color: $mainColor;
}

.yes-btn {
  max-width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: $white;
  border-radius: 12px;
  background-color: $ceruleanBlue;
  padding: 0.75rem;

  &:disabled {
    cursor: not-allowed;
    background-color: $lightGray;
  }
}

.back-btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #8d8d8d;
  text-decoration: underline;
  background-color: transparent;
  padding: 0.75rem 2.5rem;
}

.filter {
  background-color: $mainColor;
  color: $white;
  border-radius: 12px;
  width: 5.563rem;
  font-weight: 700;
  font-size: 16px;
  height: 2.563rem;
  @media (max-width: 768px) {
    height: 3rem;
    width: 10rem;
    font-size: 16px;
  }
}
.reset-button {
  background-color: $white;
  color: $mainColor;
  font-weight: 400;
  margin-right: 1.25rem;
  border: 1px solid $mainColor;
}
.reset-button,
.search-button {
  border-radius: 12px;
  width: 6.75rem;
  height: 2.438rem;
  margin-top: 25%;
}
.search-button {
  background-color: $mainColor;
  color: $white;
  border: none;
  font-weight: 700;
}
.acceptance-button {
  margin: 1rem 1rem 0rem 0rem;
  border-radius: 10px;
  width: 3rem;
  height: 3rem;
  border: none;
  background-color: $white;
}
.list-button {
  height: 2.438rem;
  background-color: $white;
  border: none;
  font-weight: 700;
  margin-right: 1.5rem;

  color: $riverBed;
}

.filter-reset-button,
.filter-search-button {
  height: 36px;
  border-radius: 12px;
  line-height: 20px;
  font-size: 16px;
  padding: 0.5rem 0.75rem;
}

.filter-reset-button {
  background-color: $white;
  color: $mainColor;
  font-weight: 400;
  border: 1px solid $mainColor;
  font-size: 18px;
}

.filter-search-button {
  background-color: $mainColor;
  color: $white;
  border: none;
  font-weight: 700;
}

.add-comment {
  width: 10.875rem;
  height: 2.43rem;
  color: $white;
  background-color: $ceruleanBlue;
  font-weight: 600;
  border-radius: 10px;
  position: relative;
  left: -32.75rem;
}

.widder-btn {
  width: 12rem;
  height: 2.438rem;
  background-color: $ceruleanBlue;
  color: $white;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  font-size: 17px;

  &:disabled {
    cursor: not-allowed;
    background-color: $lightGray;
  }
}

.cancel {
  width: 9.875rem;
  height: 2.438rem;
  background-color: $white;
  color: $rockBlue;
  border: 1px solid $pattensBlue;
  border-radius: 10px;
  font-weight: 700;
  font-size: 17px;
}

.ok-btn {
  width: 140px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 12px;
  color: $white;
  background-color: $ceruleanBlue;
  padding: 0.75rem 0;
}

.hidden {
  display: none;
}

.disabled {
  cursor: not-allowed;
  background-color: $lightGray;
}
