.page {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;

  &__date--header {
    margin-top: 1.5%;
  }
  &__dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 4.125rem;
    @media (max-width: 768px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    overflow-y: scroll;
  }
}
