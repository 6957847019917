@import "../../../assets/styles/variables.module.scss";

.menu-item {
  &__button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    width: 100%;
    margin: 0;
    color: $white;
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  &__icon {
    display: flex;
  }

  &__title {
    margin-left: 1rem;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__expand-icon {
    margin-left: 0.5rem;
  }

  &__submenu {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      .menu-item__submenu__item {
        &__button {
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          outline: 0px;
          border: 0px;
          margin: 0px;
          padding: 0.5rem 0.75rem;
          border-radius: 0px;
          cursor: pointer;
          user-select: none;
          vertical-align: middle;
          appearance: none;
          color: inherit;
          display: flex;
          -webkit-box-pack: start;
          justify-content: flex-start;
          -webkit-box-align: center;
          align-items: center;
          position: relative;
          text-decoration: none;
          width: 100%;
          box-sizing: border-box;
          text-align: left;
          transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          &:hover {
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.04);
          }
        }

        &__title {
          flex: 1 1 auto;
          min-width: 0px;
          margin-top: 4px;
          margin-bottom: 4px;

          margin: 0px;
          font-weight: 400;
          font-size: 1rem;
          line-height: 2;
          letter-spacing: 0.00938em;
          display: block;
        }

        &__icon {
          min-width: 56px;
          color: rgba(0, 0, 0, 0.54);
          flex-shrink: 0;
          display: inline-flex;
        }
      }
    }

    &__expand-icon {
      fill: $mainColor;
      margin-top: 10%;
      margin-left: 40%;
      margin-right: 40%;
    }
  }
}

.nav-bar,
.nav-bar--extended {
  @media (max-width: 912px) {
    &__closed {
      display: none;
    }
  }

  &--extended {
    background-color: $persianInigo;
  }

  // color: $white;

  &__button {
    margin: 0;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $mainBackground;
    transition: all 0.3s ease-out;

    &--hidden {
      display: none;
    }
    
    @media (min-width: 912px) {
      display: none;
    }
  }
  
  &__button:hover {
    background-color: $lineColor;
  }

  color: $white;
  &__avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  background-color: $dogerBlue;
  width: 200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 10;

  &__logo {
    margin-top: 12%;

    &__img {
      width: 100%;
    }
  }

  &__user {
    margin-top: 1.5rem;

    padding: 0 1.5rem 1.625rem 1.5rem;
    display: flex;
    align-items: center;

    &__icon {
      width: 25%;

      .avatar {
        width: 100%;
        border-radius: 12px;
      }
    }

    &__info {
      width: 75%;
      display: flex;
      flex-direction: column;
      position: relative;

      &__container {
        margin-left: 0.5rem;
        display: flex;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        &__details {
          display: flex;
          align-items: center;

          .name {
            font-size: 16px;
            font-weight: 500;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          svg {
            margin-left: 0.5rem;
          }
        }

        &__btn {
          display: flex;
          background: none;
          align-items: center;
        }

        &__job-position {
          font-size: 14px;
          color: $white;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &__menu {
        background-color: $white;
        position: absolute;
        top: 45px;
        display: flex;
        flex-direction: column;
        border: 1px solid $pattensBlue;
        border-radius: 6px;

        .btn {
          text-decoration: none;
          white-space: nowrap;
          font-size: 14px;
          padding: 0.5rem 0.5rem;
          color: $riverBed;
          top: 80px;
          background: none;
          text-align: center;

          &:nth-child(n + 2) {
            border-top: 1px solid $pattensBlue;
          }
        }
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0;

    &--utils-field {
      padding-top: 10%;
      .separation-line {
        border-radius: 2px;
        height: 2px;
        margin: 0 1rem;
        border-top-color: $white !important;
        background-color: $white;
      }
    }
    svg {
      path {
        fill: $manatee;
      }
    }
    &__suggestions {
      margin-left: 1.5rem;
    }

    &__suggestions {
      &__link {
        padding-right: 0.75rem;
        font-size: 12px;
        background: none;
        box-sizing: border-box;
        width: 100%;
        margin-top: 0.75rem;
        display: flex;
        text-decoration: none;
        align-items: center;
        color: $white;
      }
    }
    &__assessments {
      &__link {
        padding-right: 0.75rem;
        font-size: 12px;
        background: none;
        box-sizing: border-box;
        width: 100%;
        margin-top: 0.75rem;
        display: flex;
        text-decoration: none;
        align-items: center;
        color: $white;
      }
    }
  }
}

.hidden {
  display: none;
}

.link {
  background: none;
  box-sizing: border-box;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  text-decoration: none;
  align-items: center;
  color: $white;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  &--active-selector {
    background-color: $white;
    width: 4px;
    height: 24px;
    border-radius: 0 4px 4px 0;
    position: relative;
    right: 10%;
  }

  svg {
    path {
      fill: $white;
    }
  }

  &.hidden {
    display: none;
  }

  &__name {
    margin-left: 1rem;
    font-size: 16px;
    font-weight: 500;
  }

  &.active,
  &.active--extended {
    &--extended {
      background-color: $dogerBlue;
    }
    background-color: $persianInigo;
    color: $white;
    width: 100%;

    border-radius: 0;
    svg {
      path {
        fill: $white;
      }
    }
  }
}
.sidebar__collapsed {
  border-right: 1px solid $lineColor;
  background-color: white;
  width: 100px;
  min-height: 100vh;
  .nav-bar__logo__img {
    width: 70px;
    height: 20px;
    margin-right: 25px;
  }
  .nav-bar__user__icon {
    margin-left: 28%;
    margin-top: 10%;
    cursor: pointer;
  }
}
.sidebar__full {
  border-right: 1px solid $lineColor;
  width: 214px;
  min-height: 100vh;
  .nav-bar__logo__img {
    width: 100%;
    margin-right: 25px;
  }

  .menu-icon {
    margin-left: 45%;
  }
}
.menu-icon {
  cursor: pointer;
  fill: $mainColor;
  margin-top: 10%;
  margin-left: 40%;
  margin-right: 40%;
}

.drawer {
  position: absolute;
  height: 100%;
  background-color: $mainBackground;
  transition: left 0.3s ease-in-out;

  display: none;

  &__open {
    display: block;
  }
}

.app-bar {
  &__button {
    position: fixed;
    top: 10px;
    left: 20px;

    margin: 0;
    padding: 5px;
    border: 1px solid black;
    border-radius: 50%;

    background-color: $mainBackground;
    transition: all 0.3s ease-out;

    svg {
      width: 24px; 
      height: 24px;
    }

    &:hover {
      background-color: $lineColor;
    }

    &--hidden {
      display: none;
    }
    
    @media (min-width: 912px) {
      display: none;
    }

    @media (max-width: 912px) {
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__box,
  &__box--extended {
    display: none;
    width: 250px;

    &--extended {
      background-color: $persianInigo !important;
    }

    @media (max-width: 912px) {
      display: flex;
      transform: none;
      transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0.3s;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
        0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
      overflow-y: auto;
      flex-direction: column;
      height: 100%;
      z-index: 1200;
      -webkit-overflow-scrolling: touch;
      position: fixed;
      top: 0;
      outline: 0;
      left: 0;
    }
  }

  box-shadow: none;
  display: none;
  @media (max-width: 912px) {
    display: block;
  }
}
.mobile-link {
  color: white;
  
  &.active,
  &.active--extended {
    &--extended {
      background-color: $dogerBlue !important;
    }
    background-color: $persianInigo !important;
    color: $white;
    width: 100%;

    border-radius: 0;

    svg {
      path {
        fill: $white;
      }
    }
  }
  
  &__hidden {
    display: none !important;
  }
}

.mobile--user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .name {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
}

.mobile-bar {
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 64px;
  }

  &__logo-img {
    width: 150px;
  }

  &__button {
    margin: 0;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $mainBackground;
    transition: all 0.3s ease-out;

    &--hidden {
      display: none;
    }
  }

  &__button:hover {
    background-color: $lineColor;
  }

  &__divider {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
  }

  &__list {
    list-style: none;
    margin: 0px;
    padding: 8px 0px;
    position: relative;
  }
}

.button-group {
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  width: 85%;
  margin-left: 7.5%;
  justify-content: space-between;

  .button {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    border-radius: 6px;
    background-color: transparent;
    color: $persianInigo;

    &__active {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      border-radius: 6px;

      @media (max-width: 912px) {
        width: 145%;
      }

      background-color: $persianInigo;
      color: white;

      &--extended {
        @media (max-width: 912px) {
          width: 145%;
        }
        
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        border-radius: 6px;
        background-color: $dogerBlue;
        color: $white;
      }
    }
  }
}
