@import "../../../assets/styles/variables.module.scss";

.login {
  margin-top: 5%;
  @media (max-width: 768px) {
    width: 85%;
  }
  width: 50%;

  &__header {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
  }

  &__label {
    color: $heather;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    color: $steelGray;
  }

  &__input {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    border-radius: 6px;
    border-width: 1.5px;
    border-color: $baliHai;
  }

  &__submit-button {
    width: 100%;
    border-radius: 4px !important;
    font-size: 18px !important;
    line-height: 1;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    background-color: $persianInigo;

    &:hover {
      background-color: $persianBlue !important;
    }
    &:active {
      background-color: $dogerBlue !important;
    }

    &:disabled {
      background-color: $athensGray;
      &:hover {
        background-color: $athensGray !important;
      }
    }
  }

  &__error-pargraph {
    height: 1rem;
    margin: 0.5rem 0;
    padding-bottom: 0.75rem;
    font-size: 12px;
    color: $red;
    text-align: center;
  }

  &__checkbox {
    margin-bottom: 1rem;

    .ant-checkbox-wrapper > .ant-checkbox.ant-wave-target {
      align-self: flex-start !important;
      padding-top: 5px !important;
    }

    &-description {
      font-size: 14px;
      line-height: 1.6;
      font-weight: 400;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      .required {
        color: $red;
      }
    }
  }
}
