@import "../../../assets/styles/variables.module.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & > span {
        font-weight: 500;
        font-size: 28px;
        color: $mainColor;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        cursor: pointer;
      }
    }
  }

  &__body {
    margin-top: 2rem;
    width: 100%;

    .form-group {
      margin-bottom: 1rem;
      width: 100%;

      &__input {
        box-sizing: border-box;
        color: $riverBed;
        border: 1px solid $pattensBlue;
        padding: 0.75rem;
        border-radius: 12px;
        font-size: 16px;
        width: 100%;
      }

      &__select {
        box-sizing: border-box;
        width: 100%;
        color: $riverBed;
        padding: 0.75rem;
        font-size: 16px;
        border-color: $pattensBlue;
        border-radius: 12px;
        cursor: pointer;
      }

      &__error {
        color: $red;
        margin-top: 0.5rem;
        margin-left: 0.75rem;
      }

      &__empty {
        margin-top: 0.5rem;
        height: 1rem;
      }
    }

    .form-buttons {
      display: flex;

      button {
        &:nth-child(1) {
          margin-right: 2rem;
        }
      }
    }

    .form-error {
      display: flex;
      height: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      &__text {
        color: $red;
      }
    }
  }
}
