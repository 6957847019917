@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";

.user-settings {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  padding: 1.7rem 2rem 2.25rem 2rem;

  @media (max-width: 768px) {
    padding: 0;
  }

  display: flex;
  gap: 1.5%;

  &__avatar-container {
    width: 25%;
    height: 35%;
    background-color: $white;

    @media (max-width: 768px) {
      display: none;
    }

    border-radius: 6px;
    @include standard-box-shadow();

    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    .avatar__header {
      margin-top: 3%;
      color: $steelGray;
    }
  }

  &__row-content {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 14px;
    
    flex-direction: column;

    .row {
      padding: 0.75rem;
      
      hr {
        margin: 0;
        border-radius: 2px;
        border: 1px solid $anakiwa;
      }

      &__content {
        margin-top: 1%;
        display: flex;

        &--col {
          margin-top: 1%;
          display: flex;
          flex-direction: column;

          p {
            margin-bottom: 0;
          }

          .content__column {
            display: flex;
            margin-bottom: 1%;
            align-items: center;
            color: $steelGray;
            font-size: 16px;

            .content__label {
              display: flex;
              align-items: center;
              margin-right: 0.5rem;
              color: $baliHai;
              font-size: 16px;

              svg {
                margin-right: 0.5rem;
                path {
                  fill: $baliHai;
                }
              }
            }
          }
        }

        .content__column {
          display: flex;
          align-items: center;
          color: $steelGray;
          font-size: 16px;

          .content__label {
            display: flex;
            align-items: center;

            margin-right: 0.5rem;

            color: $baliHai;
            font-size: 16px;
          }
        }

        justify-content: space-between;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      &__header {
        color: $steelGray;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .row__button {
          background-color: $persianInigo;

          &:hover {
            background-color: $persianInigo;
          }

          font-size: 14px;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          padding: 3px 12px;
          height: auto;
          color: $white;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-items: space-between;

          &__icon {
            margin-left: 3%;
          }
        }

        h2 {
          font-weight: 700;
        }
      }

      &:first-of-type,
      &:nth-of-type(2) {
        height: 17%;
      }
      &:nth-of-type(3) {
        height: 31.5%;
      }
      &:nth-of-type(4) {
        height: 35%;
      }
      width: 100%;
      border-radius: 6px;

      background-color: $white;
      @include standard-box-shadow();
    }
  }
}

.checkbox {
  margin-top: 1%;
}

.holiday-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
  border-radius: 12px;

  @media (max-width: 768px) {
    height: 100%;
  }

  &__paragraph--error {
    color: $red;
  }

  &__dates-list {
    margin-top: 3%;
    display: flex;
    flex-direction: column;

    .vacation {
      display: flex;
      width: 100%;
      align-content: center;
      justify-content: space-between;

      &__icons {
        display: flex;
        justify-content: space-between;
        align-content: center;

        .icons__icon {
          &:hover {
            cursor: pointer;
          }

          height: 22px;
          width: 22px;
        }
      }
    }
  }

  &__range-select {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .range-picker {
      height: 42px;
      border-radius: 6px !important;
      max-width: 65%;

      @media (max-width: 768px) {
        width: 100%;
      }

      font-size: 16px;
      color: $riverBed;
    }
  }

  &__icon {
    height: 22px;
    width: 22px;
  }
  
  &__header {
    display: flex;

    gap: 1%;
    align-content: center;
  }
}
