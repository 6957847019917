@import "../../../../assets/styles/variables.module.scss";

.area {
  box-sizing: border-box;
  background: white;
  max-width: 24%;
  flex: 1 0 21%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0.66%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $lineColor;

  cursor: pointer;

  @media (max-width: 768px) {
    width: 80rem;
    margin-left: 0;
  }
  @media (max-width: 280px) {
    width: 53.8rem;
    margin-left: 0;
  }

  &:nth-child(4n + 1) {
    margin-left: 0;
  }

  &:nth-child(4n + 4) {
    margin-right: 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;

    .button {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: #2256bb;
      }
    }
  }

  &__icon {
    padding-top: 3rem;
    padding-bottom: 1.25rem;

    svg {
      path {
        fill: #391580;
      }
    }
  }

  &__name {
    font-size: 16px;
    color: $riverBed;
    padding-bottom: 2rem;
    text-align: center;
    word-break: break-word;
  }
}
