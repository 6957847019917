@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";

.navtopbar {
  display: block;
  width: 90%;
  height: 20%;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  background-color: $white;
  padding: 8px 14px 11px 17px;
  @include standard-box-shadow();

  img {
    display: none;
    width: 50%;
    max-height: 100%;

    @media (max-width: 768px) {
      display: block;
    }
  }

  &__header {
    font-weight: 700;
    font-size: 28px;
    color: $cloudBurst;
    margin-bottom: 0;

    @media (max-width: 560px) {
      font-size: 20px;
    }
  }

  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5%;
  }
}
