@import "../../../assets/styles/variables.module.scss";

.notification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 30px;
  max-height: 40px;
  margin-bottom: 1%;
  background-color: $white;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid $pattensBlue;

  @media (max-width: 768px) {
    min-height: 4rem;
    padding-right: 0.1rem;
  }

  &__bell {
    margin: 0 2rem 0 0.75rem;
  }

  &__context {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    width: 90%;

    &__text {
      display: flex;

      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 90%;
      font-size: 16px;

      & > span {
        font-weight: 500;

        @media (max-width: 484px) {
          font-size: 11px;
        }

        color: $mainFontColor;
      }
    }

    &__close-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 60px;
      width: 10%;
      margin-left: 5%;
      height: 100%;
    }
  }
}

.hide {
  position: absolute;
  right: 0;
  bottom: 0;
}
.implemented-status {
  color: $americanYellow;
}
.closed-status {
  color: $frenchRaspberry;
}
.rejected-status {
  color: $frenchRaspberry;
}
.approved-status {
  color: $steelBlue;
}
.done-status {
  color: $tangerine;
}
