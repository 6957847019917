@import "../../../assets/styles/variables.module.scss";

.button {
  a {
    text-decoration: none;
    color: $white;
  }

  width: 5rem;
  height: 2.563rem;
  font-size: 15px;
  font-weight: 700;
  background-color: $mainColor;
  color: $white;
  border-radius: 12px;
  border: none;
}

.assessment-status {
  padding: 2rem;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    h2 {
      font-weight: 600;
    }
    &--close {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__show-dialog {
    background-color: transparent;
    color: $ceruleanBlue;
  }
  &__date,
  &__topic {
    display: block;
    font-size: 16px;
  }
  &__date {
    font-weight: 400;
  }

  &__container {
    padding: 1.25rem;
    border-radius: 12px;
    border: 1px solid $pattensBlue;
    color: $riverBed;
    font-size: 16px;
  
    background-color: $linkWater;
  
    .assessment-status__box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0.5rem 1rem;
      border-radius: 12px;
    }
  }

  &__comments {
    gap: 0.25%;
    padding-left: 4%;
    .comment {
      h3 {
        margin-bottom: 0;
      }
    }
  }
  &__topic {
    margin: 3% 0 1% 0;
    font-weight: 700;
  }
  &__description {
    max-height: 20%;

    font-size: 16px;
    font-weight: 400;
    margin-bottom: 3%;
  }
}
