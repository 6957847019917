@import "../../../assets/styles/variables.module.scss";

.page {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;

  .status {
    padding: 4px 10px;
    border-radius: 12px;
    background-color: #BDDEFD;
  }
  
  .dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    .header {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.7rem;

      background-color: #FFFFFF;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);

      .title-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .buttons-section {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        gap: 8px;

        padding: 12px;
        
        .additional-button {
          display: flex;
          align-items: center;

          font-weight: 500;
          background-color: $white !important;
          color: $persianInigo !important;
          border: none;
          box-shadow: none;
        }

        .secondary-button {
          display: flex;
          align-items: center;

          border: 2px solid #391580;
          border-radius: 4px;
          
          font-weight: 500;
          background-color: $white !important;
          color: $persianInigo !important;
          border-color: $persianInigo;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: #391580;
            }
          }
        }

        .primary-button {
          display: flex;
          align-items: center;

          border: 2px solid #391580;
          border-radius: 4px;

          font-weight: 500 !important;
          background-color: $persianInigo !important;

          &:disabled {
            background-color: $athensGray !important;
          }

          &:hover {
            filter: brightness(80%);
          }

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }

      display: flex;
      align-items: center;
      padding: 0 3%;
      padding-right: 6%;
      justify-content: space-between;
      height: 8%;

      @media (max-width: 912px) {
        flex-direction: column;
        flex-grow: 1;
      }  
    }

    .container {
      display: flex;
      min-height: 100vh;
      padding-top: 1.5rem;

      gap: 16px;

      .content-section {
        width: 95%;

        display: flex;
        flex-direction: column;

        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .suggestion-preview {
          margin-left: 2%;
          margin-right: 2%;
          border-radius: 6px;
          margin-top: 1.5%;
          padding: 1rem 1.1rem 1.5rem 1.1rem;
          box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.05);
          width: 96%;

          &__buttons {
            display: flex;
            gap: 0.5rem;

            button {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }

          .content-table {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 768px) {
              flex-direction: column;
              align-items: baseline;
            }
          }

          .row {
            padding: 0.75rem;
            
            hr {
              margin: 0;
              border-radius: 2px;
              border: 1px solid $anakiwa;
            }
      
            &__content {
              margin-top: 1%;
              display: flex;
      
              &--col {
                margin-top: 1%;
                margin-right: 0.5rem;

                display: flex;
                flex-direction: column;

                width: 100%;
      
                p {
                  margin-bottom: 0;
                }
      
                .content__column, .content__column--multi {
                  display: flex;
                  margin-bottom: 1%;
                  align-items: center;
                  color: $steelGray;
                  font-size: 16px;

                  &--multi {
                    align-items: flex-start;
                    flex-direction: column;

                    .content__row {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }
      
                  .content__label {
                    display: flex;
                    align-items: center;
                    margin-right: 0.5rem;
                    color: $baliHai;
                    font-size: 16px;
      
                    svg {
                      margin-right: 0.5rem;
                      path {
                        fill: $baliHai;
                      }
                    }
                  }
                }
              }
      
              .content__column {
                display: flex;
                align-items: center;
                color: $steelGray;
                font-size: 16px;
      
                .content__label {
                  display: flex;
                  align-items: center;
      
                  margin-right: 0.5rem;
      
                  color: $baliHai;
                  font-size: 16px;
                }
              }
      
              justify-content: space-between;
      
              @media (max-width: 768px) {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}


.timeline {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  &__heading {
    display: flex;
    justify-content: space-between;

    width: 100%;
  }

  &__date {
    color: #8391AD;
    font-weight: 400;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__user {
    margin: 0;
    font-weight: 700;
  }

  &__description {
    margin: 0;
  }
}
