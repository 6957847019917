@import "../../../../assets/styles/variables.module.scss";

.department {
  background: white;
  max-width: 24%;
  flex: 1 0 21%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0.5rem;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $lineColor;
  cursor: pointer;
  transform: translate(0, 0);
  @media (max-width: 768px) {
    width: 70rem;
  }

  @media (max-width: 280px) {
    width: 40rem;
  }
  &.dragging {
    width: 100px !important;
    height: 100px !important;
    opacity: 0.5;

    .department__name {
      display: none;
    }
  }

  &.supervisor {
    background: $ghostWhite;
  }

  &:nth-child(4n + 1) {
    margin-left: 0;
  }

  &__drag {
    align-self: flex-end;
    padding: 1rem;
    cursor: grab;
  }

  &__icon {
    padding-top: 3rem;
    padding-bottom: 1.25rem;
  }

  &__name {
    font-size: 16px;
    color: $riverBed;
    padding-bottom: 2rem;
    text-align: center;
    word-break: break-word;
  }
}
