@import "../../../assets/styles/variables.module.scss";

.popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 3rem 0;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    & > span {
      text-align: center;
      font-weight: 500;
      font-size: 22px;
      color: $mainColor;
    }
  }

  &__comments {
    width: 100%;
    padding: 0 2rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: $riverBed;

    &__header {
      padding-left: 1.25rem;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }

    &__comment {
      margin-top: 1rem;
      padding: 0.5rem 1.25rem 0.5rem 1.25rem;
      border: 1px solid $pattensBlue;
      border-radius: 12px;

      &__date {
        margin: 0;

        .bold {
          font-weight: 500;
        }
      }

      &__author {
        margin: 0;

        .bold {
          font-weight: 500;
        }
      }
    }
  }

  &__comment {
    width: 100%;
    padding: 0 2rem;

    &__header {
      font-weight: 500;
      font-size: 22px;
      color: $riverBed;
      margin: 2rem 0 1rem;
    }

    &__paragraph {
      margin: 0 0 0.75rem 0;
      color: $riverBed;
      font-size: 17px;
      font-weight: 500;
    }

    &__text-areas {
      display: flex;
      flex-direction: column;

      .text-areas--big {
        padding: 0.5rem 0.5rem 0 0.5rem;
        width: 100%;
        height: 7.75rem;
        resize: none;
        border-radius: 10px;
        border: 1px solid $pattensBlue;
      }

      .text-areas__error {
        margin-top: 1rem;
        color: $red;
      }
    }
  }

  &__send {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    padding: 0 2rem;

    .cancel {
      margin-right: 2rem;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 3rem;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(1) {
        margin-right: 2.5rem;
      }
    }
  }
}

.button {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e6e9;
  border-radius: 12px;
  background-color: $white;
  color: $rockBlue;
  transition: 0.3s;
  font-weight: bold;
  font-size: 18px;
  padding: 0.5rem 0;

  &:hover {
    background-color: $mainColor;
    color: $white;
  }
}
