@import "../../../assets/styles/variables.module.scss";

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  &__backdrop {
    background-color: $modalBackgGroundColor;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
  }

  &__box {
    box-shadow: 0px 0px 50px 21px rgba(76, 105, 161, 0.05);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: $white;
    z-index: 101;
    border-radius: 6px;

    &.add-user-form {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 560px;
      min-width: 400px;
      padding: 2.5rem 3.25rem 4rem 3.25rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.assessment-info {
      min-height: 20%;
      max-height: 95%;
      flex-direction: column;
      align-content: stretch;
      width: 45%;

      @media (max-width: 768px) {
        width: 90%;
        height: 100%;
      }
    }
    &.user-info {
      @media (max-width: 768px) {
        width: 20rem;
      }
    }

    &.make-assessment {
      max-height: 90vh;
      align-content: stretch;
      min-height: 45%;
      width: 40%;
      @media (max-width: 768px) {
        width: 90%;
        min-height: 65%;
        max-height: 75%;
      }
    }

    &.suggestion-info-ronly,
    &.evaluate-form,
    &.repeal-form,
    &.suggestion-info,
    &.add-user-form,
    &.update-user-form,
    &.change-login,
    &.change-password,
    &.approved-suggestion,
    &.end_result,
    &.note-form,

    &.evaluate-report,
    &.evaluate-report-withdrawn,
    &.evaluate-withdrawn,
    &.evaluate-realization,
    &.evaluate-realization-during_realization,
    &.evaluate-rejection,
    &.evaluate-rejection-closed,
    &.evaluate-rejection-revocable,
    &.evaluate-appealed-appealed,

    &.evaluate-closed,
    &.evaluate-form,
    &.correct-suggestion-form,
    &.evaluate-appealed,
    &.admin-change-credential,
    &.hr-payments,
    &.generate-payments,
    &.accept-payments,
    &.done-payments,
    &.payment-info-modal,
    &.spinner-modal,
    &.user-info,
    &.loading {
      @media (max-width: 768px) {
        width: 23rem !important;
      }
    }

    &.create-department,
    &.create-area,
    &.area-info,
    &.create-job-position,
    &.edit-area,
    &.edit-department,
    &.edit-job-position {
      @media (max-width: 768px) {
        width: 24rem;
      }
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 560px;
      padding: 2.5rem 3.25rem;
    }

    &.update-user-form {
      margin: 5rem auto 2rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 560px;
      min-width: 400px;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.create-department-success,
    &.create-department-fail,
    &.create-area-success,
    &.create-area-fail,
    &.area-info,
    &.create-job-position-success,
    &.implemented-suggestion,
    &.edit-job-position-fail,
    &.edit-job-position-success,
    &.edit-area-fail,
    &.edit-area-success,
    &.edit-department-fail,
    &.edit-department-success,
    &.create-job-position-fail,
    &.create-department-fail,
    &.done-payments-success,
    &.done-payment-success,
    &.reject-payment-empty,
    &.reject-payment-success,
    &.payment-fail,
    &.accept-payment-empty,
    &.accept-payment-success,
    &.accept-payments-fail,
    &.done-payments-empty,
    &.accept-payments-empty,
    &.accept-payments-success,
    &.generate-payment-fail,
    &.generate-payment-empty,
    &.generate-payment-success,
    &.closed-success,
    &.implemented-success,
    &.approved-success,
    &.done-suggestion,
    &.appealed-suggestion,
    &.rejected-suggestion,
    &.supervisor-note-success,
    &.end-result-success,
    &.withdrawn,
    &.add-suggestion-error,
    &.add-suggestion-success,
    &.corrected-suggestion,
    &.implemented-suggestion,
    &.emove-evaluating-user-error,
    &.remove-user-error,
    &.removed-user,
    &.update-user-error,
    &.updated-user,
    &.add-user-error,
    &.added-user,
    &.withdraw-points,
    &.implement-suggestion,
    &.remove-user,
    &.implement-suggestion {
      @media (max-width: 768px) {
        width: 20rem;
      }
    }

    &.remove-user {
      width: 90%;
      max-width: 540px;
    }

    &.added-user,
    &.add-user-error,
    &.removed-user,
    &.remove-user-error,
    &.remove-evaluating-user-error,
    &.updated-user,
    &.update-user-error {
      width: 90%;
      max-width: 540px;
    }
    &.admin-change-credential {
      width: 100%;
      max-width: 600px;
    }

    &.change-password {
      width: 100%;
      max-width: 360px;
      padding: 2.25rem 2rem;
    }

    &.change-login {
      width: 100%;
      max-width: 360px;
      padding: 2.25rem 2rem;
    }

    &.login-page {
      top: 35%;
      width: 25.25rem;
      height: 22.5rem;
      padding: 4rem 2.5rem 4rem 2.5rem;
    }

    &.suggestion-form {
      width: 50rem;
      @media (max-width: 768px) {
        border-radius: 0;
        max-width: 100vw;
        height: 400vh;
        margin-bottom: 0;
        margin-top: 0;
      }

      padding: 2.5rem 2.75rem 2.5rem 3.25rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.suggestion-info {
      width: 50rem;
      padding: 2.5rem 2rem 4rem 2rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.correct-suggestion-form,
    &.evaluate-form,
    &.evaluate-appealed,
    &.evaluate-appealed-appealed {
      width: 50rem;
      padding: 2.5rem 2rem 4rem 2rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.supervisor-note-success,
    &.end-result-success,
    
    &.evaluate-report,
    &.evaluate-report-withdrawn,
    &.evaluate-withdrawn,
    &.evaluate-realization,
    &.evaluate-realization-during_realization,
    &.evaluate-rejection,
    &.evaluate-rejection-revocable,
    &.evaluate-rejection-closed,
    &.evaluate-closed
    {
      width: 90%;
      max-width: 540px;
    }

    &.implement-suggestion {
      top: 50%;
      width: 35rem;
    }

    &.note-form {
      width: 40rem;
      padding: 2.5rem 2rem 2.5rem 2rem;
      margin: 10rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.end_result {
      width: 40rem;
      padding: 2.5rem 2rem 2.5rem 2rem;
      margin: 10rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.approved-suggestion {
      width: 50rem;
      padding: 2.5rem 2rem 4rem 2rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.suggestion-info-ronly {
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.user-info,
    &.loading {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 600px;
      min-width: 500px;
      padding: 2.5rem 3.25rem 2.5rem 3.25rem;
      margin: 6rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.reject-payment {
      display: flex;
      background: none;
      justify-content: center;
    }

    &.accept-payment,
    &.accept-payments,
    &.generate-payments,
    &.done-payments,
    &.done-payment {
      width: unset;
      display: flex;
      background: none;
      justify-content: center;
    }

    &.approve-payments {
      width: unset;
      display: flex;
      background: none;
      justify-content: center;
    }

    &.payment-info-modal {
      width: unset;
      display: flex;
      background: none;
      justify-content: center;
    }

    &.spinner-modal {
      background: unset;
      display: flex;
      justify-content: center;
    }
  }
}

.backdrop--modal {
  background-color: transparent;
}
