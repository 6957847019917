.your-profile {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;

  .dashboard {
    padding: 0 4.125rem;
    @media (max-width: 912px) {
      padding: 0 1.15rem;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
