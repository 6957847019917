@import "../../../../assets/styles/variables.module.scss";

.breadcrumb {
  color: $riverBed;
  font-size: 16px;
  margin: 0;
  padding: 1rem 0;
  display: flex;
  cursor: pointer;

  .arrow {
    margin: 0 0.5rem;
    display: none;
  }

  &:nth-child(n + 2) {
    .arrow {
      display: flex;
    }
  }

  &:nth-last-child(1) {
    font-weight: 500;

    .arrow {
      font-weight: 400;
    }
  }
}
