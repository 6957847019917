$kashmirBlue: #4c69a1;
$perwinkle: #c1d6ff;
$hawkesBlue: #d5dbfb;
$ceruleanBlue: #2256bb;
$scampi: #6470ab;
$camouflageGreen: #77906f;
$peppermint: #e1f3db;
$Derby: #ffecd6;
$harvestGold: #ddad71;
$riverBed: #1c2946;
$seashell: #f1f1f1;
$darkGray: #464e5f;
$red: #c02d47;
$grey: #fcfcff;
$buttonShadow: #ccc;
$cloudBurst: #1c2946;
$mainColor: #2256bb;
$mercury: rgba(148, 165, 199, 0.05);
$modalBackgGroundColor: rgb(34, 86, 187, 0.6);
$rockBlue: #a5b4d0;
$pattensBlue: #e0e6e9;
$white: #ffffff;
$ghostWhite: #f2f3f8;
$red: #c02d47;
$bostonBlue: #3e8ca5;
$lavender: #f8f8f9;
$manatee: #8791a4;
$denim: #1c71c0;
$linkWater: #fcfcff;
$black: #000000;
$lightGray: #f1f2f3;
$azuerishWhite: #dee6f5;
$lineColor: #e8ecf3;
$mainBackground: #f7f9fc;
$linkBlue: #e8edf8;
$aliceBlue: #e6f1ff;
$lightBlue: #6da0e1;
$borderBlue: #99c8ff;
$breakLine: #afbee1;
$queenpink: #efcbd1;
$columbiaBlue: #cee2e8;
$steelBlue: #3e8ca5;
$frenchRaspberry: #c02d47;
$americanYellow: #f6b100;
$tangerine: #f18404;
$teaGreen: #d2e7cb;
$mainFontColor: #1d2a45;
$spunPearl: #a7a7af;
$dogerBlue: #3fa2ff;
$persianInigo: #391580;
$steelGray: #2d273a;
$trolleyGray: #808080;
$anakiwa: #9ccefc;
$carnation: #f1485d;
$limeade: #3ec302;
$persianBlue: #2123b3;
$athensGray: #ebedf1;
$torchRed: #f27875;
$flamenco: #ff7f11;
$baliHai: #8391ad;
$mountainMeadow: #20bf55;
$heather: #c2cad6;
$malibu: #7abdfa;
$pattensBlue: #deeffe;
$cloudBurst: #1c2946;
$silver: #c6c6c6;
$alta: #d9d9d9;
