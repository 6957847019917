@import "../../../../assets/styles/variables.module.scss";

.position {
  background: $mainBackground;
  color: $riverBed;
  font-size: 14px;
  padding: 0.5rem 3.5rem;
  list-style: none;
  border: 1px solid $lineColor;
  border-radius: 6px;
  margin-top: 0.75rem;
  transform: translate(0, 0);
  cursor: grab;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    margin-top: 0;
  }

  &.supervisor {
    background-color: $borderBlue;
  }

  &__button {
    margin-left: 0.5rem;
    padding: 0.25 0.5rem;
    background: none;
  }
}
