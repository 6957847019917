@import "../../../assets/styles/variables.module.scss";
@import "antd/dist/reset.css";

.your-profile {
  display: flex;
  justify-content: space-between;
  color: $riverBed;
  width: 100%;
  min-height: 100vh;

  &__notification-container {
    width: 100%;
    @media (max-width: 768px) {
      display: block;
    }

    @media (min-width: 900px) {
      overflow: auto !important;
    }
    background-color: $white;
    border: none;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    padding: 2% 4% 2% 4%;
  }

  &__your-stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: space-evenly;
    width: 100%;
  }

  &__your-stats {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    &__notifications {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100% !important;
      height: 100% !important;
      position: relative;
      transition: 0.3s;

      &.between {
        justify-content: space-between;
      }

      &.hide {
        height: 0px;
      }
    }

    &__header {
      font-size: 17px;
      font-weight: 700;
      margin: 0;
      margin-bottom: 1rem;
      color: $mainFontColor;
    }
  }

  &__global-stats {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    width: 100%;

    .boss-profile {
      width: 100%;
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .all-suggestion {
        border: 1px solid $pattensBlue;
        border-radius: 12px;
        flex: 1;

        @media (max-width: 768px) {
          width: 92%;
          margin-right: 5%;
          margin-top: 3%;
          margin-bottom: 3rem;
        }

        &__header {
          font-size: 17px;
          text-align: center;
          margin: 1rem;
        }

        &__amount {
          text-align: center;
          font-size: 40px;
          font-weight: 700;
          margin: 0;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__workers-stats-container {
    align-items: flex-end;
    width: 28%;
    display: flex;
    min-height: 870px;
    box-sizing: border-box;

    @media (max-height: 950px) {
      min-height: 780px;
    }
    @media (max-height: 900px) {
      min-height: 740px;
    }

    @media (max-height: 700px) {
      min-height: 550px;
    }
  }

  &__workers-stats {
    box-sizing: border-box;
    width: 100%;
    background-color: $white;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 700px;
    max-height: 80vh;

    @media (max-height: 900px) {
      max-height: 650px;
      min-height: 605px;
    }

    @media (max-height: 700px) {
      min-height: 500px;
      max-height: 550px;
    }
  }
}

.all {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.workers-stats-list {
  border: 1px solid $pattensBlue;
  background-color: $linkWater;
  padding: 2.5rem 0;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.user-stats__header {
  margin-bottom: 0.5rem;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
}

.hidden {
  display: none;
}

.stretch {
  width: 100%;
}

.alerts-button {
  background-color: $queenpink;
  width: 60%;
  height: 20%;
  color: $frenchRaspberry;
  border: none;
  cursor: pointer;
}

.panel {
  border-top: none;
  overflow: auto !important;
  @media (max-width: 768px) {
    width: 92% !important;
    margin-right: 5% !important;
    margin-top: 3% !important;
    margin-bottom: 5% !important;
    display: block;
  }
}
