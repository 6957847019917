@import "../../../assets/styles/variables.module.scss";

.status-card--supervisor {
  width: 80%;
  height: 30%;
  margin: 3% 0;
  border: 1px solid $pattensBlue;
  background-color: $white;
  color: $riverBed;
  border-radius: 12px;
  padding-bottom: 1rem;
  &__amount {
    font-size: 28px;
    font-weight: 700;
  }
}
.status-card {
  padding: 0 0 2% 2%;
  width: 30%;
  background-color: $white;
  color: $white;
  border-radius: 12px;
  position: relative;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 90%;
    margin-right: 7%;
    margin-top: 3%;
  }

  &__header {
    font-size: 17px;
    margin: 1rem;
    height: 1rem;
  }
  &__amount {
    font-size: 40px;
    font-weight: 700;
    margin: 0.5rem 1rem;
  }
  &__icon {
    position: absolute;
    bottom: -6px;
    right: 0;
  }

  &:nth-child(3n - 2) {
    background-color: $bostonBlue;
  }
  &:nth-child(3n - 1) {
    background-color: $denim;
  }
  &:nth-child(3n) {
    background-color: $ceruleanBlue;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  transition: 0.3s all ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.status-card--boss {
  background-color: $bostonBlue;
  @media (max-width: 768px) {
    width: 92%;
    margin-right: 5%;
    margin-top: 3%;
  }
  padding: 0 0 2% 2%;
  flex: 1;
  color: $white;
  border-radius: 12px;
  position: relative;
  margin-right: 1rem;
  cursor: pointer;

  &__header {
    font-size: 17px;
    margin: 1rem;
    height: 1rem;
  }
  &__amount {
    font-size: 40px;
    font-weight: 700;
    margin: 0.5rem 1rem;
  }
  &__icon {
    position: absolute;
    bottom: -6px;
    right: 0;
  }

  &:nth-child(2) {
    background: $denim;
  }
}
