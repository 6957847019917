@import "../../../assets/styles/variables.module.scss";

.card {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 90%;
  height: 10rem;

  margin-top: 0;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 10px;

  background-color: #389CF8;
  padding: 0;
  padding-left: 0.5rem;
  color: $white;

  overflow: hidden;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    width: 95%;
  }


  .title {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    margin: 1.5rem 0 1rem;
    text-align: center;
  }

  .point-container {
    width: 60%;
    padding-top: 1rem;
    padding-bottom: 1.125rem;

    .points {
      font-size: 57px;
      font-weight: 700;
      line-height: 1;
      margin: 0 0 2rem 0;
      text-align: center;
    }
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    padding-top: 10%;
    padding-bottom: 10%;

    .icon-wrapper {
      display: flex;
      align-items: center;

      .icon {
        margin-left: 10px;
        display: flex;
        align-items: center;
        position: relative;
      }

      .position {
        position: absolute;
        transform: translateX(185%);
        margin-left: 5px;
        font-size: 57px;
        font-weight: 800;
        line-height: 1;
      }
    }
  }

  &:nth-child(1) {
    background-color: #389CF8;

    .position {
      color: #389CF8;
    }
  }

  &:nth-child(2) {
    background-color: #9CCEFC;

    .position {
      color: #9CCEFC;
    }
  }

  &:nth-child(3) {
    background-color: #391580;

    .position {
      color: #391580;
    }
  }
}
