@import "../../../../assets/styles/variables.module.scss";

.departments {
  width: 215px;
  background: $linkBlue;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 5%;
    bottom: 46%;
    position: absolute;
    width: 92vw;
    height: 37%;
  }

  @media (max-width: 375px) {
    margin-top: 5%;
    bottom: 32%;
    position: absolute;
    width: 92vw;
    height: 47%;
  }

  @media (max-width: 280px) {
    margin-top: 15%;
    bottom: 34%;
    position: absolute;
    width: 92vw;
    height: 45%;
  }

  &__header {
    font-size: 16px;
    font-weight: 500;
    color: $riverBed;
    margin: 1rem 0 0 1rem;
  }

  &__supervisor-department {
    box-sizing: border-box;
    list-style: none;
    margin: 0 1rem;
    padding: 2rem 0 1.5rem 0;
    border-bottom: 1px solid $breakLine;
    @media (max-width: 768px) {
      overflow-x: auto;
    }
  }

  &__list {
    list-style: none;
    margin-top: 1.5rem;
    padding: 0 1rem 1rem;
    overflow: scroll;
    @media (max-width: 768px) {
      height: 14vh !important;
      display: flex;
      overflow-x: auto;
      max-height: 80vh;
      align-items: center;
    }
    height: 65vh;

    &__department {
      position: relative;
      margin-top: 1rem;
      background: $white;
      padding: 0.875rem;
      border-radius: 7px;
      cursor: pointer;

      &.active {
        background: $ceruleanBlue;
        color: $white;
      }

      &:nth-child(1) {
        margin-top: 0;
      }

      &__name {
        font-size: 16px;
        color: $riverBed;
        padding-bottom: 2rem;
        text-align: center;
        word-break: break-all;
      }

      .child-departments {
        position: absolute;
        top: 0;
        left: 215px;
        background-color: $white;
        border: 1px solid $lineColor;
        padding: 0;
        list-style: none;
        border-radius: 12px;

        li {
          color: $riverBed;
          font-size: 16px;
          border-top: 1px solid $lineColor;
          white-space: nowrap;
          padding: 1rem 3rem 1rem 1.25rem;

          &:nth-child(1) {
            border-top: none;
          }
        }
      }
    }
  }
}
