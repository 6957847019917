@import "../../../assets/styles/variables.module.scss";

.recovery {
  box-shadow: 0px 4px 40px rgba(76, 105, 161, 0.2);
  border-radius: 10px;
  background-color: $white;
  padding: 4rem 2.5rem 1.25rem;
  z-index: 10;
  @media (max-width: 768px) {
    width: 23rem;
  }

  &__header {
    font-size: 34px;
    font-weight: 700;
    color: $ceruleanBlue;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &__description {
    p {
      font-size: 18px;
      margin: 0;
      color: $manatee;
    }
    margin-bottom: 1.75rem;
  }

  &__input {
    input {
      font-size: 18px;
      border-color: $pattensBlue;
      color: $riverBed;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $riverBed;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $riverBed;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $riverBed;
      }
    }
  }

  &__error-pargraph {
    margin: 0;
    padding-top: 0.75rem;
    font-size: 12px;
    height: 1rem;
    color: $red;
  }

  &__buttons {
    margin-top: 2rem;

    button {
      font-size: 18px;
      width: 100%;
    }
  }
}

.recovery-message {
  box-shadow: 0px 4px 40px rgba(76, 105, 161, 0.2);
  border-radius: 10px;
  background-color: $white;
  padding: 4rem 2.5rem 4rem;
  z-index: 10;
  margin-top: 2rem;

  &__header {
    margin-top: 0;
    margin-bottom: 1rem;

    &__text {
      font-size: 34px;
      font-weight: 700;
      color: $ceruleanBlue;
      margin: 0;
    }
  }

  &__description {
    &__text {
      font-size: 18px;
      margin: 0;
      color: $manatee;
    }
    margin-bottom: 1.75rem;
  }
}
