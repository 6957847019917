@import "../../../assets/styles/variables.module.scss";

.popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 70px;

  &__header {
    width: 60%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 0 10% 0;

    & > span {
      text-align: center;
      font-weight: 500;
      font-size: 22px;
      color: $mainColor;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 60%;
    height: 50%;

    &__button {
      width: 45%;
      height: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.button {
  width: 100%;
  height: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e6e9;
  border-radius: 12px;
  background-color: $white;
  color: $rockBlue;
  transition: 0.3s;
  font-weight: bold;
  font-size: 18px;

  &:hover {
    background-color: $mainColor;
    color: $white;
  }
}
