@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";

.add-response {
  width: 100%;
  margin-top: 1rem;

  &__text-area {
    resize: none;
    border-radius: 12px;
    width: 100%;
    height: 10rem;
    font-size: 16px;
    padding-top: 0.5rem;
    &--buttons {
      display: flex;
      justify-content: right;
      margin-top: 2.5%;
      button {
        &:disabled {
          cursor: not-allowed;
          background-color: lightgray;
        }
        font-weight: 700;
        border-radius: 12px;
        background-color: $mainColor;
        color: $white;
        padding: 0.8rem 1rem;
      }
    }
    @include form-input-border($spunPearl);
    padding-left: 0.8rem;
    color: $spunPearl;

    @media (max-width: 768px) {
      height: 4rem;
    }
  }
}
