@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/global.module.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 8% 12%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 90%;

      &__icon {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        background-color: $mainColor;
        margin-right: 5%;
        box-sizing: border-box;
        padding: 2%;
      }

      & > span {
        font-weight: 500;
        font-size: 28px;
        color: $black;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        cursor: pointer;
      }
    }
  }

  &__context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 2rem;

    &__login {
      width: 100%;
      &__label {
        margin-bottom: 0.5rem;
      }

      &__error {
        margin-top: 0.7rem;
        color: $red;
        height: 1rem;
      }
      &__button {
        margin-top: 1rem;
      }
    }

    &__password {
      width: 100%;
      margin-top: 1.5rem;

      &__input-container {
        &__label {
          margin-bottom: 0.5rem;
        }

        &__input {
          box-sizing: border-box;
          padding: 11px 12px;
          width: 100%;
          height: 50%;
          border: 1px solid #e0e6e9;
          border-radius: 12px;
          font-size: 16px;
          color: $riverBed;

          &::placeholder {
            color: $riverBed;
          }
        }

        &.last {
          margin-top: 1rem;
        }
      }

      &__btn-container {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          flex-direction: column;
          justify-content: space-between;
        }

        &__btn {
          margin-right: 3rem;
        }
      }
    }
  }
}

.input {
  box-sizing: border-box;
  padding: 11px 12px;
  width: 100%;
  height: 50%;
  border: 1px solid #e0e6e9;
  border-radius: 12px;
  font-size: 16px;
  color: $riverBed;

  &::placeholder {
    color: $riverBed;
  }
}

.error-message {
  height: 20%;
  color: $red;
}
