.info {
  margin-top: 4%;
  width: 50%;
  @media (max-width: 768px) {
    width: 85%;
  }

  &__header {
    margin-top: 3%;
  }
}
