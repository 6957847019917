.suggestions-page {
    display: flex;
    position: relative;
    max-width: 100vw;
    min-height: 100vh;
  
    .dashboard {
      @media (max-width: 768px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      @media (max-width: 924px) {
        overflow-y: auto;
      }
  
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 0 3rem;
      overflow-x: hidden;
    }
}
  