@import "../../../assets/styles/variables.module.scss";

.all-suggestion {
  background-color: $white;
  height: 100%;
  width: 100%;
  border: 1px solid $pattensBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  @media (max-width: 768px) {
    width: 92%;
    margin-bottom: 3%;
  }
  &__container {
    display: flex;
    height: 108%;
    width: 100%;
    flex-direction: column;
    margin-bottom: 18%;
    margin-left: 10%;
    @media (max-width: 768px) {
      margin-left: 0;
      height: 50%;
    }
  }

  &__header {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10%;
    margin-left: 0.625rem;
  }

  &__amount {
    margin: 40px 0 0;
    text-align: center;
    font-size: 120px;
    font-weight: 700;
    color: $mainFontColor;
  }
}

.all-suggestion-suppervisor {
  width: 80%;
  height: 30%;
  margin: 3% 0;
  border: 1px solid $pattensBlue;
  background-color: $white;
  color: $riverBed;
  border-radius: 12px;

  &__header {
    font-size: 17px;
    margin: 1rem;
    font-weight: 400;
  }
  &__amount {
    font-size: 40px;
    font-weight: 700;
    margin: 0.5rem 1rem;
    margin-bottom: 1rem;
  }
}
