.previous {
  margin-top: 1%;
  margin-right: 18rem;
}
.next {
  margin-top: 1%;
  margin-left: 13.75rem;
}
.my-assessment-header {
  width: auto;
  text-align: left;
  &:first-of-type {
    padding-left: 1.5%;
  }

  margin: 0;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #2d273a;
  font-weight: 700;
}

.my-assessment-rows {
  td {
    height: 10%;
    overflow-y: hidden;
    &:nth-of-type(5) {
      width: auto;
    }

    &:nth-of-type(4) {
      width: 15%;
    }

    &:nth-of-type(6) {
      width: 5%;
      padding-left: 1.5%;
    }

    &:nth-of-type(3),
    &:nth-of-type(2) {
      width: 10%;
    }
    &:first-of-type {
      width: 5%;
      padding-left: 1.5%;
    }
  }
}

.positive__td,
.negative__td {
  border-radius: 12px;
  padding: 2% 3%;
  font-size: 14px;
}

.positive__td {
  color: #2d273a;
  background-color: #9ccefc;
}

.negative__td {
  color: #ffffff;
  background-color: #2123b3;
}
@media screen and (max-width: 768px) {
  .my-assessment-rows {
    /* height: 40px; */
    display: none;
  }
  .previous {
    margin-right: 16.5vw;
  }
  .next {
    margin-left: 5vw;
    margin-right: 1.5vw;
  }
}
