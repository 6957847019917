@import "../../../assets/styles/global.module.scss";

.page {
  width: 100vw;
  height: 100vh;
  display: flex;

  &__logos-container {
    width: 50%;
    height: 100%;
    display: flex;
    gap: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;

    background-color: $dogerBlue;

    svg {
      border: 1px solid transparent;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__forms-container {
    width: 50%;
    height: 100%;
    display: flex;
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__mobile-icon {
      display: none;
      @media (max-width: 768px) {
        margin-top: 4%;
        margin-bottom: 3%;
        display: block;
      }
    }
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 4%;
    
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      align-items: center;
    }

   .logo {
      height: 100px;
      max-width: 70%;

      &--gobarto {
        height: 85px;
        max-width: 65%;
      }

      &--silesia {
        width: 20rem;
        height: 10rem;
  
        @media (max-height: 640px) {

        }
      }

      &--pgf {
          width: 10rem;
          height: 25%;
          @media (max-width: 768px) {
            width: 25%;
          }
      }
    }
  }
}
