.bonus-page {
  display: flex;
  position: relative;
  max-width: 100vw;
  min-height: 100vh;

  background-color: #F6F7F9;

  .pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
  
    padding: 2px 6px 2px 6px;
    border-radius: 4px;

    font-size: 12px;
    line-height: 17px;

    background-color: #9CCEFC;

    &:hover {
      background-color: #FFF;
    }

    span {
      @media(max-width: 768px) {
        display: none;
      }
    }

    @media(max-width: 768px) {
      background-color: #DEEFFE;
    }
  }

  .status {
    padding: 4px 10px;
    border-radius: 12px;
    background-color: #BDDEFD;
  }
  
  .dashboard {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;

    .header {
      padding-top: 1rem;
      padding-left: 1.7rem;
      background-color: #FFFFFF;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);

      @media (max-width: 912px) {
        padding-left: 4rem;
      }  
    }

    .container {
      display: flex;
      min-height: 100vh;
      padding-top: 1.5rem;

      gap: 16px;

      .cards-section {
        @media (max-width: 768px) {
          width: 95%;
          order: 999;
          justify-content: center;
        }
      }

      .content-section {
        width: 95%;

        display: flex;
        flex-direction: column;

        margin-left: 0.5rem;
        margin-right: 0.5rem;

        display: flex;
        flex-direction: column;

        .worker-table {


          .date-mobile {
            display: flex;
            flex-direction: column;
            gap: 4px;

            &__date {
              font-size: 14px;
            }

            &__amount {
              font-size: 16px;
              font-weight: 700;
            }
          }

          .points-mobile {
            display: flex;
            flex-direction: column;

            gap: 4px;

            text-align: right;

            &__status {
              font-size: 14px;
            }

            &__points {
              font-size: 14px;
            }
          }
        }

        table {
          min-height: 33vh;

          td:empty {
            padding: 0;
          }

          @media (max-width: 768px) {
            width: 90vw;
          }

          @media(max-width: 575px) {
            thead {
              display: none;
            }

            td {
              border-bottom: 1px solid #D9D9D9;
            }
          }
        }

        .table-empty {
          color: #000;
        }

        .bonus-instructions {
          margin-bottom: 2rem;
          padding: 0.5rem;
          border-radius: 6px;

          background-color: #FFF;

          @media (max-width: 912px) {
            width: 90%;
          }
        }

        @media (max-width: 912px) {
          width: 65vw;
        }
          
        @media (max-width: 768px) {
          width: 100vw;

          justify-content: center;
          align-items: center;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 768px) {
    min-height: 100vh;
  }
}

  