@import "../../../assets/styles/variables.module.scss";

.structure-page {
  display: flex;
  position: relative;
  max-width: 100vw;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding-right: 5%;
  }

  .dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .header {
      padding: 2.5rem 2rem 0 1.5rem;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        padding-right: 0;
        padding-left: 0;
        flex-direction: column;
      }
      @media (max-width: 280px) {
        padding-left: 8%;
        padding-right: 0;
        flex-direction: column;
      }
      justify-content: space-between;

      &__text {
        margin: 0;
        @media (max-width: 768px) {
          margin-right: 65%;
        }
        color: $riverBed;
        font-size: 28px;
        font-weight: 700;
      }

      &__buttons {
        @media (max-width: 360px) {
          display: flex;
          justify-content: center;
          align-content: center;
          flex-direction: column;
          gap: 6px
        }

        button {
          margin: 0 0.5rem;
        }
      }
    }
  }
}
