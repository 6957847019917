@import "../../../assets/styles/variables.module.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 2.5rem 3.25rem 4rem 3.25rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
    width: 100%;

    &__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & > span {
        font-weight: 500;
        font-size: 28px;
        color: $ceruleanBlue;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        cursor: pointer;
      }
    }
  }

  &__context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;

    &__activation-box {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;

      width: 100%;
      min-height: 40px;
      margin-bottom: 1rem;

      .activation-box__body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 1rem;
      }

      .activation-box__section {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .activation-box__description {
        font-size: 14px;
        text-align: justify;
      }

      .activation-box__tags {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 2rem;

        @media (max-width: 768px) {
          flex-direction: column;
          > span {
            margin: 1rem 0;
          }
        }
      }

      .activation-box__radio {
        display: flex;
        justify-content: space-between;
  
        margin-top: 1.25rem;
        margin-bottom: 2rem;
  
        font-size: 18px;
  
        .radio__group {
          min-width: 60%;
          display: flex;
          justify-content: space-evenly;
          font-weight: 500;
  
          .radio__element {
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
          }
        }
  
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
  
      .activation-box__decision {
        display: flex;
        flex-direction: row;
        flex-direction: column;
        justify-content: space-evenly;
  
        @media (max-width: 768px) {
          flex-direction: column;
        }

      label {
        display: flex !important;
        align-items: center !important;
        padding: 0.5rem 0 !important;

        h3 {
          margin: 0;
          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }
      }
  
        .decision__section {
          margin-bottom: 0.5rem;
          border: 1px solid $pattensBlue;
          border-radius: 12px;
          background: $linkWater;
          padding: 1rem 1.25rem;
          color: $riverBed;
          font-size: 18px;
  
          &--inactive {
            margin-bottom: 0.5rem;
            border: 1px solid $pattensBlue;
            border-radius: 12px;
            background: $linkWater;
            padding: 1rem 1.25rem;
            color: $riverBed;
            font-size: 18px;
            filter: grayscale(1);
          }
        }
      }

      .activation-box__footer {
        .activation-switch{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          
          .switch-label {
            margin-left: 1rem;

            span {
              white-space: pre-wrap !important;
            }
          }

        }
      }

      .activation-select {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .activation-description {
        min-width: 20%;
        font-size: 16px;
      }
    }

    &__input-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 40px;
      max-height: 50px;
      margin-bottom: 1rem;
    }

    &__checkbox-container {
      color: $riverBed;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 40px;
      max-height: 50px;
      margin-bottom: 1rem;
      padding-left: 0.75rem;

      .formLabel {
        font-size: 16px;
        margin-left: 0.5rem;
      }
    }

    &__select-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 40px;
      max-height: 50px;
      height: 10%;
      margin-bottom: 1rem;
      padding: 10px;
      position: relative;

      background-color: $white;
      border: 1px solid #e0e6e9;
      color: $riverBed;
      border-radius: 12px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      &::after {
        pointer-events: none;
        display: block;
        content: "";
        border: solid $riverBed;
        border-width: 0 1px 1px 0;
        padding: 4px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: rotate(45deg) translateY(-100%);
        -webkit-transform: rotate(45deg) translateY(-100%);
      }
    }
  }

  &__section-role {
    margin-top: 1rem;
    width: 100%;

    span {
      font-size: 16px;
      color: $riverBed;
      padding-left: 13px;
    }
  }

  &__error {
    margin-top: 1rem;
    height: 1rem;
    width: 100%;

    &__message {
      display: inline-block;
      color: $red;
      height: 1rem;
      padding-left: 13px;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    &__button-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      padding: 0 1% 0 0;

      button {
        margin-top: 0;
        font-size: 18px;
        font-weight: 700;
        width: 8rem;
      }
    }
  }
}

.input {
  box-sizing: border-box;
  padding: 11px 12px;
  width: 100%;
  height: 100%;
  border: 1px solid #e0e6e9;
  border-radius: 12px;
  font-size: 16px;
  color: $riverBed;

  &::placeholder {
    color: $riverBed;
  }
}

.select {
  border-radius: 12px;
  outline: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  color: $riverBed;
  font-size: 16px;
  font-weight: 400;
  padding: 0.75rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
