@import "../../../assets/styles/variables.module.scss";

.button {
  a {
    text-decoration: none;
    color: $white;
  }

  width: 5rem;
  height: 2.563rem;
  font-size: 15px;
  font-weight: 700;
  background-color: $mainColor;
  color: $white;
  border-radius: 12px;
  border: none;
}

.attachment-component {
  padding: 1.25rem;
  border-radius: 12px;
  border: 1px solid $pattensBlue;
  color: $riverBed;
  font-size: 18px;

  background-color: $linkWater;

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    border-radius: 12px;

    .files {
      width: 100%;
      display: flex;
      align-items: center;

      background-color: #DEEFFE;
      border-radius: 6px;

      margin-bottom: 0.25rem;
      padding: 0.25rem;
      
      &__filename {
        width: 50%;
        margin: 0 5%;
        flex-grow: 1;
      }

      .icon {
        cursor: pointer;
      }
    }

    .attachment__header {
      text-align: left;
    }
    
    .attachment__buttons {
      display: flex;
      justify-content: space-around;
      width: 35%;

      @media (max-width: 768px) {
        flex-direction: column;

        .button {
          margin: 0.2rem 0;
        }
      }
    }    

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}
