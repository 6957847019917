@import "../../../assets/styles/variables.module.scss";

.evaluate {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 70px;

  &__header {
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 0 10% 0;

    & > span {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.5;
      color: #2D273A;
    }
  }

  .buttons-section {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 12px;

    .secondary-button {
      display: flex;
      align-items: center;

      border: 2px solid #391580;
      border-radius: 4px;
      
      font-weight: 500;
      background-color: $white !important;
      color: $persianInigo !important;
      border-color: $persianInigo;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: #391580;
        }
      }
    }

    .primary-button {
      display: flex;
      align-items: center;

      border: 2px solid #391580;
      border-radius: 4px;

      font-weight: 500 !important;
      background-color: $persianInigo !important;

      &:disabled {
        background-color: $athensGray !important;
      }

      &:hover {
        filter: brightness(80%);
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
