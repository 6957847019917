@import "../../../assets/styles/variables.module.scss";

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.25rem;

  &__header {
    font-size: 28px;
    margin: 0;
    color: $ceruleanBlue;
  }

  &__icon {
    margin-right: 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }
}

.holiday-info {
  margin: 1% auto;
  color: $tangerine;
  display: flex;
  align-items: center;

  .holiday-icon {
    width: 24px;
    height: 24px;
  }
}
