@import "../../../assets/styles/variables.module.scss";

.suggestion-info {
  position: relative;
  cursor: pointer;
  border: 1px solid #e0e6e9;
  border-radius: 12px;
  box-sizing: border-box;
  margin-bottom: 0.75rem;
  width: 100%;
  height: 5.5rem;
  background-color: $white;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 18px;
  word-break: break-all;
  transition: background-color 0.3s ease;

  &::before {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: $ceruleanBlue;
    color: $white;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
    pointer-events: none;
  }

  &:hover {
    background-color: $lightGray;
  }

  &:hover::before {
    opacity: 1;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    width: 96%;
    height: 25%;
  }
  &__date {
    width: 16%;
    word-break: break-word;
    @media (max-width: 768px) {
      display: none;
      font-size: 14px;
    }
  }

  &__user-info {
    box-sizing: border-box;
    width: 12%;

    text-align: center;

    @media (max-width: 768px) {
      width: 30%;
    }
    &.no-employee {
      padding-left: 0;
    }

    .user-info__username {
      .user-info__icon-container {
        position: relative;
        max-width: 40px;

        .user-info__name {
          padding: 0.5rem;
          display: none;
          margin: 0;
          white-space: nowrap;
          position: absolute;
          top: 0;
          left: 40px;
          background-color: $ceruleanBlue;
          color: $white;
          border-radius: 12px;

          p {
            margin: 0;
          }
        }

        &:hover {
          .user-info__name {
            display: block;
          }
        }
      }
    }
  }

  &__department {
    width: 25%;
    word-break: break-word;

    @media (max-width: 768px) {
      display: none;
    }
    p {
      margin: 0;
    }
  }

  &__tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0.25rem 0.75rem;
    margin: 0;

    border-radius: 20px;

    background-color: $americanYellow;

    @media (max-width: 768px) {
      display: none;
    }

    & .tag-description {
      margin: 0;
      padding: 0;
      word-break: normal;
    }

    &[data-type=MICROIMPROVEMENT] {
      background-color: $teaGreen;
    }
  
    &[data-type=INVESTMENT] {
      background-color: $azuerishWhite;
    }

    &[data-type=BACK] {
      @media (max-width: 768px) {
        display: flex;
      }
    }
  }

  &__problems {
    margin: 0.2rem;
    word-break: break-all;

    &--container {
      width: 30%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        width: 40%;
        justify-content: center;
        flex-direction: column;
        height: 100px;
        margin: 0;
      }
    }

    &--tags-container {
      width: 15%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        width: 0;
        display: none;
      }
    }

    p {
      margin: 0;
      padding-right: 50px;
      word-break: break-all;

      @media (max-width: 768px) {
        position: absolute;
        max-width: 50px;
        left: 78%;
      }
      line-height: 18px;
      max-height: 36px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__buttons {
    word-break: normal;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 30%;
    }
  }

  .span__status {
    flex-grow: 1;
  }
}

.user-info__icon {
  margin: 0.2rem 0 0 0.3rem;
}
.container__username {
  display: inline-block;
  margin: 2% 3% 0 0;
}
.container__span {
  &:first-of-type {
    margin-left: 3%;
  }
}

.user-info__elipse {
  width: 2rem;
  height: 2rem;
  border-radius: 17px;

  background-color: $riverBed;
}

.suggestion-info__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  .buttons__button {
    display: flex;
    padding: 4px;
    border-radius: 8px;
    background-color: $mercury;
    &:hover {
      cursor: pointer;
    }
  }
  .hidden {
    display: none;
  }
  .span__status {
    font-weight: 600;
  }
}

.tooltip {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  background-color: $white !important;
  color: $riverBed !important;
  width: 150%;
  &__container {
    display: flex;
    align-items: center;
    .container__span {
      margin-right: 3%;
    }
  }
}

.evaluate {
  font-size: 16px;
  font-weight: 700;
  border: 1px solid $pattensBlue;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  color: $white;
  background: $ceruleanBlue;
  @media (max-width: 768px) {
    width: 5.75rem;
  }
}
