@import "../../../assets/styles/variables.module.scss";

.admin-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;

  &__filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 0px;
    transition: 0.3s;
    overflow: hidden;
    box-sizing: border-box;

    &.show {
      height: 70px;

      @media (max-width: 768px) {
        height: 150px !important;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      & > * {
        font-weight: 700;
        font-size: 22px;
        color: $riverBed;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: 100%;
      max-height: 1200px;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      &__selectors {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        width: 70%;
        max-width: 800px;
        @media (max-width: 768px) {
          width: 100%;
        }

        &__select-container {
          display: flex;

          align-items: center;
          justify-content: center;
          height: 100%;
          width: 40%;
          margin-right: 5%;
          position: relative;

          &::after {
            pointer-events: none;
            display: block;
            content: "";
            border: solid $riverBed;
            border-width: 0 1px 1px 0;
            padding: 5px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: rotate(45deg) translateY(-100%);
            -webkit-transform: rotate(45deg) translateY(-100%);
          }
        }
      }

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        max-width: 350px;
        width: 100%;

        @media (max-width: 768px) {
          margin-top: 15px;
          margin-right: 15px;
          justify-content: center;
        }

        &__button-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 100%;
          padding: 0 1% 0 0;

          &:nth-child(1) {
            margin-right: 1rem;
          }

          button {
            margin-top: 0;
          }
        }
      }
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 2rem;
    @media (max-width: 768px) {
      padding-left: 5%;
    }

    & > span {
      font-weight: 700;
      font-size: 22px;
      color: $riverBed;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 2rem;

    button {
      width: 6rem;
      height: 2.6rem;

      margin: 0.5rem;
      padding: 0.5rem;
      border-radius: 12px;

      font-weight: 700;
      font-size: 16px;
      background-color: $mainColor;
      color: $white;

      &[disabled] {
        filter: grayscale(1);
      }
    }
  }

  &__list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-height: 900px;
    transition: 0.3s;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 1rem 0;
    background-color: transparent;
    box-sizing: border-box;

    & > * {
      box-sizing: border-box;
      color: $riverBed;
    }

    &__position {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 5%;
      height: 100%;
      padding: 0 1%;

      & > span {
        font-weight: 700;
        font-size: 22px;
      }
    }

    &__worker {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 35%;
      height: 100%;
      padding: 0 1%;

      & > span {
        font-weight: 700;
        font-size: 22px;
      }
    }

    &__department {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 50%;
      @media (max-width: 768px) {
        width: 35%;
      }
      height: 100%;
      padding: 0 1%;

      & > span {
        font-weight: 700;
        font-size: 22px;
      }
    }

    &__under-departments {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 40%;

      height: 100%;
      padding: 0 1%;

      & > span {
        font-weight: 700;
        font-size: 22px;
      }
    }

    &__activation {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 768px) {
        width: 20%;
      }
      width: 10%;
      height: 100%;
      padding: 0 1%;

      & > span {
        font-weight: 700;
        font-size: 22px;
      }
    }
  }

  &__elements {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

    box-sizing: border-box;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
}

.user {
  position: relative;
  cursor: pointer;
  border: 1px solid #e0e6e9;
  border-radius: 12px;
  box-sizing: border-box;
  margin-bottom: 0.75rem;
  width: 100%;
  height: 5.5rem;
  background-color: $white;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 18px;
  transition: background-color 0.3s ease;

  &::before {
    content: attr(data-tip);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: $ceruleanBlue;
    color: $white;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
    word-break: break-word;
    max-width: 75vw;
    white-space: normal;
    text-align: center;
    pointer-events: none;
  }

  &:hover {
    background-color: $lightGray;
  }

  &:hover::before {
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    width: 96%;
  }

  & > * {
    box-sizing: border-box;
    color: $riverBed;
  }

  &__position {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 5%;
    height: 100%;
    padding: 0 1%;

    & > span {
      font-weight: 700;
      font-size: 18px;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 35%;
    height: 100%;
    padding: 0 1%;
    @media (max-width: 768px) {
      justify-content: center;
    }

    &__image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 5%;
    }

    &__avatar {
      margin-right: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: $ceruleanBlue;
      border-radius: 50%;

      & > img {
        width: 75%;
        height: 75%;
      }
    }

    &__name {
      margin-left: 5%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      flex-grow: 4;

      @media (max-width: 768px) {
        display: none;
      }

      & > span {
        font-weight: 400;
        font-size: 18px;
      }
    }
  }

  &__department {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
      width: 35%;
    }
    width: 50%;
    height: 100%;
    padding: 0 1%;

    & > span {
      font-weight: 400;
      font-size: 18px;
    }
  }

  &__under-department {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    height: 100%;
    padding: 0 1%;

    & > span {
      font-weight: 400;
      font-size: 18px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: 20%;
    }
    width: 10%;
    height: 100%;
    position: relative;

    &__icons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: 70px;
      width: 100%;
      height: 100%;

      svg {
        padding: 5px;
      }
    }

    &__menu {
      position: absolute;
      display: none;
      background-color: $white;
      border-radius: 12px;
      box-shadow: 0px 5px 20px 0px #00000040;
      top: 53px;
      right: 52px;
      width: 160px;
      z-index: 20;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -9px;
        right: 40px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
      }

      &.open {
        display: block;
      }

      button {
        text-align: left;
        width: 100%;
        padding: 0.6rem;
        background: none;
        font-size: 14px;
        color: $riverBed;
      }

      button:nth-of-type(2) {
        border-top: 1px solid $pattensBlue;
        border-bottom: 1px solid $pattensBlue;
      }
    }
  }
}

.select {
  border-radius: 12px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid #e0e6e9;
  color: $riverBed;
  font-size: 17px;
  font-weight: 400;

  @media (max-width: 768px) {
    margin-top: 5%;
    display: block;
    width: 200px;
    margin-left: 35%;
  }
  padding: 4%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
