@import "../../../../assets/styles/variables.module.scss";

.area-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 20rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & > span {
        font-weight: 500;
        font-size: 28px;
        color: $mainColor;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        cursor: pointer;
      }
    }
  }

  &__body {
    width: 100%;


    .area-content {
      margin-bottom: 1rem;
      width: 100%;

      &__header {
        margin-right: 0.25rem;
      }

      &__list {
        max-height: 55vh;
        overflow-y: auto;
      }

      // &__item {}

      &__empty {
        margin-top: 0.5rem;
        height: 1rem;
      }
    }
  }
}
