@import "../../../../assets/styles/variables.module.scss";

.nested-department {
  color: $riverBed;
  white-space: nowrap;
  padding: 0.5rem 2rem 0.5rem 1rem;

  &.active {
    background: $aliceBlue;
  }

  &:nth-child(n + 2) {
    border-top: 1px solid $lineColor;
  }

  &__name {
    margin: 0;
  }

  .child-container {
    display: none;
    position: absolute;
    margin-top: -1.5rem;

    &.active {
      display: flex;
    }

    &__list {
      padding: 0;
      overflow: hidden;
      margin-left: 1rem;
      border-radius: 12px;
      border: 1px solid $lineColor;
    }
  }
}
