@import "../../../assets/styles/variables.module.scss";

.suggestions-dashboard {
  &__statusBtn {
    margin-top: 1rem;

    @media (max-width: 974px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 5%;
      column-gap: 6%;
    }

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 5%;
      column-gap: 6%;
      width: 90vw;
    }

    &.boss {
      display: flex;
      justify-content: space-between;

      @media (max-width: 974px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 5%;
        column-gap: 6%;
        width: 96vw;
      }

      @media (max-width: 768px) {
        display: grid;
        min-height: 160px;
        max-height: 470px;
        width: 96vw;
        overflow-y: auto;
        grid-template-columns: 1fr;
        row-gap: 6%;
      }
    }
  }

  &__listHeader {
    padding: 2.5rem 1.25rem 1rem 1.25rem;
    font-size: 20px;
    font-weight: 500;
    box-sizing: border-box;
    display: flex;

    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
      margin-top: 5%;
    }
  
      &__date {
        width: 16%;
        @media (max-width: 768px) {
          display: none;
        }
      }
  
      &__person {
        width: 16%;
        text-align: center;
        @media (max-width: 768px) {
          text-align: center;
          width: 35%;
        }
      }
  
      &__department {
        width: 25%;
        text-align: center;
  
        @media (max-width: 768px) {
          display: none;
        }
      }
  
      &__issue {
        width: 30%;
        text-align: left;
        @media (max-width: 768px) {
          width: 40%
        }
      }
  
      &__type {
        @media (max-width: 768px) {
          display: none;
        }
        width: 15%;
      }
  }

  &__list {
    height: calc(100vh - 255px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    &.filters {
      height: calc(100vh - 307px);
    }
  }
}

.status-btn {
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid $pattensBlue;
  border-radius: 12px;

  font-size: 16px;
  font-weight: 600;
  background: $white;
  color: $riverBed;

  @media (max-width: 974px) {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    width: 96%;
    height: 75px;
  }

  &.active {
    background: $mainColor;
    color: $white;
  }

  &:nth-child(n + 2) {
    @media (max-width: 768px) {
      margin-left: 0;
    }
    margin-left: 1rem;
  }

  &.boss {
    width: calc(50% - 0.5rem);

    &:nth-child(n + 2) {
      margin-left: 0;
    }
  }
}
