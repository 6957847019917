@import "../../../assets/styles/variables.module.scss";

.top-bar {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;

  &__top {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    box-sizing: border-box;
    align-items: center;

    @media (max-width: 974px) {
      flex-direction: column;
      align-content: center;
      align-items: stretch;
    }

    &__left {
      display: flex;

      .header {
        margin: 0;
        color: $riverBed;
        font-size: 28px;
        font-weight: 700;
        min-width: 350px;
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 974px) {
        width: 100%;
        margin-top: 0.5rem;
        justify-content: center;
      }

      button {
        &:nth-child(1) {
          margin-right: 1rem;
        }
      }

      .filter {
        margin-right: 3rem;
      }

      .btn-group {
        margin: 0 1.5rem;

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }

  &__down {
    box-sizing: border-box;

    .switch-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 12px;
      width: 100%;
      background-color: $ghostWhite;

      &.user {
        background: none;
      }

      .button {
        font-size: 18px;
        color: $riverBed;
        padding: 0.625rem 1.5rem;
        flex: 1;
        background: none;
        border: none;
        font-weight: 700;
        margin: 3px 0;
        transition: 0.3s;
        border-radius: 12px;

        &.active {
          box-shadow: 0 0 3px 0px #dadcde;
          background-color: $mainColor;
          color: $white;
        }

        &.hr,
        &.finance {
          &:nth-child(1) {
            margin-right: 1rem;
          }
        }

        &.all {
          &:nth-child(2) {
            margin-right: 1.5rem;
            margin-left: 1.5rem;
          }
        }
      }

      &__switch {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        height: 100%;
        background-color: $white;
        position: relative;
        border-radius: 12px;
        box-shadow: 0 0 3px 0px #dadcde;

        &::before {
          content: "";
          display: block;
          background-color: $mainColor;
          width: 33%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
          border-radius: 12px 0 0 12px;
          transition: 0.3s;
        }
        &.isAdmin::before {
          left: 30%;
          border-radius: 0 12px 12px 0;
        }

        &__element {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33%;
          height: 100%;
          z-index: 1;
          cursor: pointer;

          & > span {
            font-weight: 500;
            font-size: 18px;
            color: $mainColor;
            transition: 0.3s;
          }

          & > span.active {
            color: $white;
          }
        }
      }
    }
  }
}

.btn {
  &:first-of-type {
    height: 2.813rem;
  }
  &:disabled {
    background-color: lightgray;
    cursor: not-allowed;
  }
  border: 1px solid $pattensBlue;
  color: $riverBed;
  font-size: 18px;
  padding: 0.625rem;
  background: $white;
  border-radius: 12px;
}
