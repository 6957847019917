@import "../../../assets/styles/variables.module.scss";

.filters {
  box-sizing: border-box;
  transition: 0.4s;
  overflow: hidden;
  height: 0px;

  &.show {
    height: 36px;
    margin-bottom: 1rem;
    @media (max-width: 974px) {
      height: 25vh;
    }
  }

  .filter-section__buttons {
    margin-left: 4rem;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    &.hidden {
      display: none;
    }

    button {
      margin-top: 0;
    }
  }

  .filter-section__header {
    margin-top: 1rem;
    margin-bottom: 0;
    width: 100%;
  }

  &__container {
    display: flex;

    .range-picker {
      height: 36px;
      padding: 0.5rem;
      border-radius: 12px;
      
      font-size: 16px;
      color: $riverBed;
    }
    

    @media (max-width: 974px) {
      flex-direction: column;
      align-items: left;
    }
    .select {
      @media (max-width: 768px) {
        margin-top: 3%;
        width: 96%;
        margin-left: 0;
      }
      @media (max-width: 974px) {
        margin-top: 3%;
        width: 100%;
        margin-left: 0;
      }
      height: 36px;
      width: 300px;
      padding: 0.5rem;
      border: 1px solid $pattensBlue;
      background: none;
      margin-left: 1.5rem;

      border-radius: 12px;
      font-size: 16px;
      color: $riverBed;
      cursor: pointer;

      &:focus-visible {
        outline: none;
      }
    }

    .buttons {
      display: flex;
      margin-left: 1.5rem;

      @media (max-width: 974px) {
        margin-left: 0;
        margin-top: 3%;
        justify-content: center;
      }

      button {
        margin-right: 1rem;
      }
    }
  }
}
