@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";

.attachment-error {
  color: $red;
}

.upload {
  &--open {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
}  

.create-assessment {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--footer {
      display: flex;
      align-content: center;
      justify-content: right;

      &__errors {
        display: flex;
        align-content: center;
        justify-content: space-between;
        width: 100%;
        p {
          color: $red;
        }
      }
      button {
        &:disabled {
          cursor: not-allowed;
          background-color: lightgray;
        }
        font-weight: 700;
        background-color: $mainColor;
        border-radius: 12px;
        color: $white;
        font-size: 16px;
        padding: 0.5rem;
      }
    }

    &--input,
    &--add-description {
      border-radius: 8px;
      color: $spunPearl;
      padding: 0.75rem;
      @include form-input-border($spunPearl);
    }

    &--add-description {
      resize: none;
      height: 10rem;
    }

    select {
      outline: none;
      @include form-input-border($spunPearl);
      border-radius: 8px;
      padding-left: 0.5rem;
      color: $spunPearl;
      height: 2rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
