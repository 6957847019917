@import "../../../assets/styles/variables.module.scss";

.popup {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 20px;

  background-color: $white;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.05);

  & > span {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    color: $black;
  }

  &__button {
    background: none;
    display: flex;
    align-items: center;
    margin-left: auto;

    svg {
      path {
        fill: #8391AD;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    justify-content: flex-start;
    align-items: start;

    & > span {
      text-align: left;
    }

    &__button {
      order: -1;
    }
  }
}
