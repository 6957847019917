@import "../../../assets/styles/mixins.module.scss";
@import "../../../assets/styles/variables.module.scss";

.full-width {
  @include form-input-border($kashmirBlue);
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 20rem;
  }

  ::placeholder {
    color: $kashmirBlue;
  }

  @media (max-width: 768px) {
    width: 20rem;
  }
  margin-top: 1.5rem;
  width: 100%;
  height: 2.5rem;
  padding-left: 0.75rem;
}
.search {
  border-radius: 4px;
  border: none;
  width: 12.875rem;
  height: 2.563rem;
  border-radius: 10px;
  @include form-input-border($seashell);
  ::placeholder {
    color: $riverBed;
    font-size: 30px;
  }
  @media (max-width: 768px) {
    width: 96%;
    margin-right: 4%;
  }

  padding: 0 1rem 0 1rem;
}

.hidden {
  display: none;
}
.worker-possition {
  left: 60rem;
}

.search-container {
  position: relative;
  width: 60rem;
  margin-right: 0.5rem;
  @media (max-width: 768px) {
    width: 96%;
    margin-right: 4%;
  }
}

.search-placeholder {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  span {
    opacity: 0.5;
  }
}

.search-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: $kashmirBlue;
  margin-right: 0.5rem;
}

.search {
  width: 100%;
  height: 3rem;
  padding-left: 2.25rem;
  padding-right: 0.5rem;
  border-radius: 10px;
}
