@import "../../../assets/styles/variables.module.scss";

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  &__backdrop {
    background-color: $modalBackgGroundColor;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
  }

  &__box {
    box-shadow: 0px 0px 50px 21px rgba(76, 105, 161, 0.05);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: $white;
    z-index: 101;
    border-radius: 6px;

    &.add-user-form {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 560px;
      min-width: 400px;
      padding: 2.5rem 3.25rem 4rem 3.25rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.update-user-form {
      margin: 5rem auto 2rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 560px;
      min-width: 400px;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.remove-user {
      width: 90%;
      max-width: 540px;
    }

    &.added-user,
    &.withdraw-points,
    &.add-user-error,
    &.removed-user,
    &.remove-user-error,
    &.remove-evaluating-user-error,
    &.updated-user,
    &.update-user-error {
      width: 90%;
      max-width: 540px;
    }
    &.admin-change-credential {
      width: 100%;
      max-width: 600px;
    }

    &.change-password {
      width: 100%;
      max-width: 360px;
      padding: 2.25rem 2rem;
    }

    &.change-login {
      width: 100%;
      max-width: 360px;
      padding: 2.25rem 2rem;
    }

    &.login-page {
      top: 35%;
      width: 25.25rem;
      height: 22.5rem;
      padding: 4rem 2.5rem 4rem 2.5rem;
    }

    &.suggestion-form {
      width: 50rem;
      padding: 2.5rem 2.75rem 2.5rem 3.25rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.suggestion-info {
      width: 50rem;
      padding: 2.5rem 2rem 4rem 2rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      @media (max-width: 768px) {
        width: 23rem;
      }
      right: unset;
    }
    &.consult-suggestion-form,
    &.evaluate-form,
    &.evaluate-appealed {
      width: 50rem;
      @media (max-width: 768px) {
        width: 20rem !important;
      }
      padding: 2.5rem 2rem 4rem 2rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.supervisor-note-success,
    &.end-result-success,
    
    &.evaluate-report,
    &.evaluate-report-withdrawn,
    &.evaluate-withdrawn,
    &.evaluate-realization,
    &.evaluate-realization-during_realization,
    &.evaluate-rejection,
    &.evaluate-rejection-closed,
    &.evaluate-closed,
    &.evaluate-rejection-revocable
    {
      width: 90%;
      max-width: 540px;
    }

    &.implement-suggestion {
      top: 50%;
      width: 37.5rem;
      height: 16.563rem;
    }

    &.note-form,
    &.end_result {
      width: 50rem;
      padding: 2.5rem 2rem 4rem 2rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.approved-suggestion {
      width: 50rem;
      padding: 2.5rem 2rem 4rem 2rem;
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.suggestion-info-ronly {
      margin: 5rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.user-info {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 600px;
      min-width: 500px;
      padding: 2.5rem 3.25rem 2.5rem 3.25rem;
      margin: 6rem auto 2rem;
      transform: unset;
      top: unset;
      left: unset;
      right: unset;
    }

    &.reject-payment {
      display: flex;
      background: none;
      justify-content: center;
    }

    &.accept-payment,
    &.accept-payments,
    &.generate-payments,
    &.done-payments,
    &.done-payment,
    &.hr-payments {
      width: unset;
      display: flex;
      background: none;
      justify-content: center;
    }

    &.approve-payments {
      width: unset;
      display: flex;
      background: none;
      justify-content: center;
    }

    &.payment-info-modal {
      width: unset;
      display: flex;
      background: none;
      justify-content: center;
    }

    &.spinner-modal {
      background: unset;
      display: flex;
      justify-content: center;
    }
  }
}

.backdrop--modal {
  background-color: transparent;
}
