@import "../../../assets/styles/variables.module.scss";

.ranking {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: hidden;

  &__filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 0px;
    transition: 0.3s;
    overflow: hidden;

    box-sizing: border-box;
    margin: 1% 0;

    &__selectors {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      height: 35%;
      width: 100%;
      @media (max-width: 768px) {
        height: 90%;
        flex-direction: column;
      }

      max-width: 800px;

      &__select-container {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
          margin-bottom: 5%;
          width: 100%;
        }
        height: 100%;
        width: 30%;
        margin-right: 5%;

        .range-picker {
          height: 36px;
          padding: 0.5rem;
          border-radius: 12px;
          
          font-size: 16px;
          color: $riverBed;
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 35%;

      max-width: 350px;
      width: 100%;

      &__button-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        padding: 0 1% 0 0;

        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
        }

        button {
          margin-top: 0;
          font-size: 18px;
        }
      }
    }
  }

  &__top {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    justify-content: center;
    box-sizing: border-box;
    height: 30%;
    width: 100%;
  }

  &__list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    transition: 0.3s;
    @media (max-width: 768px) {
      //
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1% 0;
    background-color: transparent;

    & > * {
      box-sizing: border-box;
      color: $riverBed;
    }

    &__position {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10%;
      margin-left: 2%;
      padding: 0 1%;

      & > span {
        font-weight: 700;
        font-size: 22px;
      }
    }

    &__department {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 100%;
      padding: 0 1%;
      @media (max-width: 768px) {
        & > span {
          font-weight: 700;
          font-size: 18px;
        }
      }

      & > span {
        font-weight: 700;
        font-size: 22px;
      }
    }
    &__points {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10%;
      height: 100%;
      margin-right: 1rem;
      @media (max-width: 768px) {
        & > span {
          font-weight: 700;
          font-size: 18px;
        }
      }

      & > span {
        font-weight: 700;
        font-size: 18px;
      }
    }
    &__worker {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 100%;
      & > span {
        font-weight: 700;
        font-size: 22px;
      }
      @media (max-width: 768px) {
        padding: 0 1%;

        & > span {
          font-weight: 700;
          font-size: 18px;
        }
      }

      &__department {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 40%;
        height: 100%;
        padding: 0 1%;
        @media (max-width: 768px) {
          & > span {
            font-weight: 700;
            font-size: 16px;
          }
        }

        & > span {
          font-weight: 700;
          font-size: 22px;
        }
      }

      &__points {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        height: 100%;
        margin-right: 1rem;
        @media (max-width: 768px) {
          & > span {
            font-weight: 700;
            font-size: 16px;
          }
        }

        & > span {
          font-weight: 700;
          font-size: 22px;
        }
      }
    }
  }

  &__elements {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .worker {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 4rem;
    flex-grow: 1;
    width: 100%;

    @media (max-width: 1000px) {
      max-height: 100px;
      min-height: 80px;
      width: 100%;
    }
    margin: 8px 0;
    border-radius: 12px;
    border: 1px solid $pattensBlue;

    & > * {
      box-sizing: border-box;
      color: $riverBed;
      font-size: 18px;
      font-weight: 700;
    }

    &__position {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10%;
      height: 100%;
      padding: 0 1%;
      color: $ceruleanBlue;

      & > span {
        font-weight: 700;
        font-size: 18px;
      }
    }

    &__user {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 100%;
      padding: 0 1%;

      &__avatar {
        margin-right: 2rem;
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
          margin-right: 0;
        }
      }

      &__name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        flex-grow: 4;

        & > span {
          font-weight: 500;
          font-size: 18px;
        }
      }
    }

    &__department {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 100%;
      padding: 0 1%;

      & > span {
        font-weight: 500;
        font-size: 18px;
      }
    }

    &__points {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10%;
      height: 100%;
      margin-right: 1rem;

      & > span {
        font-weight: 700;
        font-size: 20px;
        color: $ceruleanBlue;
      }
    }
  }

  .top-worker {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 25%;
    border-radius: 12px;
    background-color: $white;
    position: relative;
    box-sizing: border-box;
    margin: 0 2% 0 0;
    padding-top: 1.5rem;
    padding-bottom: 1rem;

    @media (max-width: 768px) {
      margin-right: 0;

      width: 100%;
      margin-bottom: 5%;
    }

    &:nth-child(1) {
      background-color: $ceruleanBlue;
    }

    &:nth-child(2) {
      background-color: $denim;
    }
    &:nth-child(3) {
      background-color: $bostonBlue;
    }

    &__position {
      position: absolute;
      left: 35px;
      top: 30px;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      & > span {
        font-weight: 700;
        font-size: 32px;
        color: $white;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $white;
      width: 100%;
      height: 100%;
    }
    &__avatar {
      & > svg {
        width: 64px;
        height: 64px;

        & > circle {
          fill: rgba(255, 255, 255, 0.15);
        }
      }
    }

    &__name {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 20%;
      margin-top: 1rem;

      & > span {
        font-size: 18px;
        color: $white;
        display: none;
      }
    }

    &__points {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 20%;
      margin-top: 0.5rem;

      & > span {
        font-weight: 500;
        font-size: 18px;
        color: $white;
      }
    }
  }
}

.show {
  height: 25%;

  @media (max-width: 768px) {
    height: 55%;
  }
}

.resize {
  height: 45%;
}

.select {
  border-radius: 12px;
  outline: none;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid #e0e6e9;
  color: $riverBed;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem;
  cursor: pointer;
}
.worker__user__name {
  @media (max-width: 768px) {
    display: none !important;
  }
}
