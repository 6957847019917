@import "../../../assets/styles/global.module.scss";

.page {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;

  &__dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 4.125rem;
    @media (max-width: 768px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.topbar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25%;
}
