@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";

.top-bar {
  background-color: $white;
  overflow: hidden;
  height: 10%;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  @media (max-width: 768px) {
    display: none;
  }

  font-family: "Roboto", sans-serif;

  @include standard-box-shadow();
  &__header {
    color: $steelGray;
    margin: 0;
    font-size: 28px;
    font-weight: 700;
  }
}
