.attachment {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 80vw;
  padding: 1rem;

  &__icon {
    bottom: 90%;
    left: 95%;
    cursor: pointer;
    position: absolute;

    @media (max-width: 768px) {
      left: 90%;
    }
  }

  &__image {
    display: flex;
    max-width: 92%;
    border-radius: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__description {
    margin: 0.5rem auto;
    text-align: center;
  }
}
